import React, {useState,useEffect} from "react";
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Formrevision1 from './form01/formrevisionA';

import Journallistaddspec from "../pages/journal/planning/journallistaddspec";
import Journallistbyapprove from "./journal/journallistbyapprove";
import Journalapprove from "./journal/components/componentapprove/journalapprove";
import Journallistnewpartb from "./journal/rd/journallistnewpartb";
import Journallistnewparta from "./form01/journallistnewparta";
import Journalallstatus from "./journal/journalallstatus";
import Journalrecallstatus from "./journal/journalrecallstatus";
import Journalstatusapprove from "./journal/journalstatusapprove";
import Flowsystem from "./flows/flowsystem";
import Login from "./login";
import Index from "./index";
import Journallistrecieved from "./journal/journallistrecieved";
import Journallistrejectstatus from "./journal/journallistrejectstatus";
import Journallistdedicate from "./journal/journallistdedicate";
import Listaccessuser from "./accessuser/listaccessuser";
import Createuser from "./accessuser/createuser";
import Journalmanagement from "./journal/journalmanagement";
import Journallistallpprovebyuser from "./journal/journallistallpprovebyuser";
import FetchApi from "./customhooks/functionFetchApi";
import Journallistprepass from "./journal/prepass/journallistprepass";
import Journallistfactory1 from "./journal/fg1/journallistfactory1";
import Journalprintinglist from "./journal/printing/journalprintinglist";
import Showdetailjournalpdf from "./journal/veiwpdf/showdetailjournalpdf";
// import Draftjournalpdf from "./journal/veiwpdf/draftjournalpdf";
import JournalPDFAll from "./journal/veiwpdf/journalPDFAll";
import DraftjournalPDFAll from "./journal/veiwpdf/draftjournalPDFAll";
import Editjournalrecall from "./journal/components/componentrecall/editjournalrecall";
import Reportpdfjournal from "./journal/veiwpdf/reportpdfjournal";
import Journallistconfirmproduct from "./journal/journallistconfirmproduct";


import Sidebar from './Sidebar';

const RoutePage = () => {
  const FetchApis=new FetchApi();

  var eccodelogin = sessionStorage.getItem("EmCode");
  var isloging = sessionStorage.getItem("Islogin");
  const[userlogin,setUserlogin]=useState(isloging);
  
  const [statusAll, setStatusAll] = useState({ preRecievd: 0, 
    preReSend: 0, 
    preReject: 0,
    draftjournal:0,
    countdedicate:0 });

  useEffect(()=>{
    var isloging = sessionStorage.getItem("Islogin");
    setUserlogin(isloging);
    loadingdatastatus();
  },[])

  function loadingdatastatus() {
    // journal/getjouranlstatusAllByemcode/
    FetchApis.FethcGet(`/journal/getjouranlstatusAllByemcode/${eccodelogin}`).then((res) => {
      setStatusAll(res.data.data[0])
    })

  }
  
  return (
    <BrowserRouter basename={'/'}>
      {userlogin!="true"? 
      <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/login" element={<Login />} />
      <Route path="/app-tds/reportpdf/:id" element={<Reportpdfjournal />} />
      </Routes>:
      <Sidebar datastatus={statusAll}>
        <Routes>
          <Route path="/newjournal" element={<Formrevision1 />} />
          <Route path="/journallistaddspec" element={<Journallistaddspec />} />
          <Route path="/journallistbyapprove" element={<Journallistbyapprove />} />
          <Route path="/journalapprove/:id" element={<Journalapprove />} />
          <Route path="/journallistnewpartb" element={<Journallistnewpartb />} />
          <Route path="/journallistparta" element={<Journallistnewparta />} />
          <Route path="/journalallstatus" element={<Journalallstatus />} />
          <Route path="/journalrecallstatus" element={<Journalrecallstatus />} />
          <Route path="/journalstatusapprove" element={<Journalstatusapprove />} />
          <Route path="/flowsystem" element={<Flowsystem />} />
          <Route path="/journallistrecieved" element={<Journallistrecieved/>} />
          <Route path="/journallistrejectstatus" element={<Journallistrejectstatus />} />
          <Route path="/journallistdedicate" element={<Journallistdedicate />} />
          <Route path="/listaccessuser" element={<Listaccessuser />} />
          <Route path="/createuser" element={<Createuser />} />
          <Route path="/journalmanagement" element={<Journalmanagement />} />
          <Route path="/Journallistallpprovebyuser" element={<Journallistallpprovebyuser />} />
          <Route path="/journallistprepass" element={<Journallistprepass />} />
          <Route path="/journallistfactory1" element={<Journallistfactory1 />} />
          <Route path="/journalprintinglist" element={<Journalprintinglist />} />
          <Route path="/showdetailjournalpdf/:id" element={<Showdetailjournalpdf />} />
          <Route path="/journalPDFAll/:id" element={<JournalPDFAll />} />
          <Route path="/draftjournalPDFAll/:id" element={<DraftjournalPDFAll />} />
          <Route path="/journallistconfirmproduct" element={<Journallistconfirmproduct />} />

          <Route path="/editjournalrecall/:id" element={<Editjournalrecall />} />
        </Routes>
      </Sidebar>}

    </BrowserRouter>
  )
}

export default RoutePage;