import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import FetchApi from '../../../customhooks//functionFetchApi';
import PopUpitemgroup from './popupitemgroup';
import PopupPackinggroup from './popupPackinggroup';
import {
    addnewitemBody, addnewitemTop, addnewitemBottom, addnewitemTab,
    removenewitemBody, removenewitemTop, removenewitemBottom, removenewitemTab
} from '../../../../reduxSlice/newItemSlice/newitemSlice';
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import TableINewitem from './tableINewitem';


const CreateNewitemToerp = ({ datajournal, CreateNewItem }) => {
    // const count = useSelector((state: RootState) => state.counter.value)
    const dispatch = useDispatch()

    const draftFG_Object = {
        newItemId: "",
        bodytype: "body",
        typeItem: "FG",
        itemegroup: "",
        itemId: "",
        itemname: datajournal.itemname,
        dimensiongroup: "SWLBS2",
        packinggroup: "",
        inventtoryUnit: "PCS",
        saleUnit: "PCS",
        purchaseUnit: "PCS",
        raf_journalname: "",
        yymm: "1",
        swanjob: "0",
        qtypertag: "",
        jobstatus: "1",
        tdscode: datajournal.tdscode,
        journalId: datajournal.journalId
    }

    const draftW_Object = {
        newItemId: "",
        typeItem: "",
        itemegroup: "",
        itemId: "",
        itemname: datajournal.itemname,
        dimensiongroup: "SWBS2",
        packinggroup: "",
        inventtoryUnit: "Sheet",
        saleUnit: "Sheet",
        purchaseUnit: "Sheet",
        raf_journalname: "PD-RF1",
        yymm: "0",
        swanjob: "1",
        qtypertag: "1500",
        jobstatus: "",
        tdscode: datajournal.tdscode,
        journalId: datajournal.journalId
    }

    const draftCE_Object = {
        newItemId: "",
        typeItem: "",
        itemegroup: "",
        itemId: "",
        itemname: datajournal.itemname,
        dimensiongroup: "SWLBS2",
        packinggroup: "",
        inventtoryUnit: "PCS",
        saleUnit: "PCS",
        purchaseUnit: "PCS",
        raf_journalname: "",
        yymm: "0",
        swanjob: "1",
        qtypertag: "1500",
        jobstatus: "",
        tdscode: datajournal.tdscode,
        journalId: datajournal.journalId
    }

    const FetchApis = new FetchApi();

    const [DraftFG, setDraftFG] = useState({ ...draftFG_Object });
    const [DraftW, setDraftW] = useState({ ...draftW_Object });
    const [DraftCE, setDraftCE] = useState({ ...draftCE_Object });
    const [dataforming, setDataForming] = useState([])
    const [groupitem, setGroupitem] = useState([]); //group item 
    const [packinggrouplist, setPackinggrouplist] = useState([]); //group item 
    const [typeitem, setTypeitem] = useState('WP');
    const [itemCreateStatus, setItemCreateStatus] = useState(false);
    const [producttype, setProducttype] = useState("body")
    const [newitemall, setNewitemall] = useState({ proBody: [], proTop: [], proBottom: [], proTab: [] });


    useEffect(() => {
        CreateNewItem(newitemall)

        getforming()
        CheckItemCreate()

    }, [newitemall])


    function CheckItemCreate() {

        FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${datajournal.journalId}`).then((res) => {
            if (res.status == 200) {
                if (res.data.data.length > 1) {
                    setItemCreateStatus(true)
                } else {
                    setItemCreateStatus(false)
                }
            }
        })
    }


    async function getforming(jourId) {

        await FetchApis.FethcGet(`/forming/formingByJournalId/${datajournal.journalId}`).then((datacoating) => {
            if (datacoating.data.status == 200) {
                setDataForming(datacoating.data.data[0])

            }

        })
    }

    function getgroupitem(values) {
        setGroupitem(values);

        setDraftFG({ ...DraftFG, itemegroup: values })
    }

    function getPackinggroup(values) {
        setPackinggrouplist(values);
        setDraftFG({ ...DraftFG, packinggroup: values })
    }


    //เอาไว้กำหนด type item create
    const onhendleTypeItem = (e) => {
        var values = e.target.value;
        setTypeitem(values)
    }
    const onhendTypeItemCE = (e) => {
        var values = e.target.checked;

        if (values) {
            setDraftCE({ ...DraftCE, typeItem: e.target.value })
        } else {
            setDraftCE({ ...DraftCE, typeItem: "" })
        }
    }

    const onhendleProductType = (e) => {
        var values = e.target.checked;
        if (values) {
            setProducttype(e.target.value)
            setDraftFG({ ...DraftFG, bodytype: e.target.value })
        }
        else {
            console.log(e.target.checked)
        }
    }

    const onChangInventUnit = (e) => {
        var values = e.target.value;
        setDraftFG({ ...DraftFG, inventtoryUnit: values })

    }

    const onChangSaleUnit = (e) => {
        var values = e.target.value;
        setDraftFG({ ...DraftFG, saleUnit: values })

    }

    const onChangPurchaseUnit = (e) => {
        var values = e.target.value;
        setDraftFG({ ...DraftFG, purchaseUnit: values })

    }

    const CheckItemConfirm = (e) => {
        var values = e.target.checked;

        if (values == true) {
            setDraftFG({ ...DraftFG, jobstatus: e.target.value })
        } else {
            setDraftFG({ ...DraftFG, jobstatus: '' })
        }
    }


    const savedraftNewitem = () => {
        if (itemCreateStatus == true) {

            Swal.fire({
                title: "แจ้งเตือน",
                text: "ไม่สามารถบันทึกได้ Item นี้มีการสร้างไปแล้ว",
                icon: "question"
            });

        } else {

            if (DraftFG.itemegroup == "" || DraftFG.itemId == "" || DraftFG.itemname == ""
                || DraftFG.packinggroup == "" || DraftFG.jobstatus == "", DraftFG.raf_journalname == "") {

                Swal.fire({
                    title: "แจ้งเตือน",
                    text: "ไม่สามารถบันทึกได้กรุณาตรวจสอบข้อมูลก่อนบันทึก",
                    icon: "question"
                });
            } else {

                let data_objectW = {
                    bodytype: producttype,
                    typeItem: typeitem,
                    itemegroup: DraftFG.itemegroup,
                    itemId: typeitem + DraftFG.itemId.substring(2),
                    itemname: DraftFG.itemname,
                    dimensiongroup: "SWBS2",
                    packinggroup: DraftFG.packinggroup,
                    inventtoryUnit: 'Sheet',
                    saleUnit: "Sheet",
                    purchaseUnit: "Sheet",
                    raf_journalname: "PD-RF1",
                    yymm: "0",
                    swanjob: "1",
                    qtypertag: "1500",
                    jobstatus: DraftFG.jobstatus,
                    tdscode: datajournal.tdscode,
                    journalId: datajournal.journalId
                }

                let data_objectCE = {
                    bodytype: producttype,
                    typeItem: DraftCE.typeItem,
                    itemegroup: DraftFG.itemegroup,
                    itemId: DraftFG.itemId.substring(2),
                    itemname: DraftFG.itemname,
                    dimensiongroup: "SWLBS2",
                    packinggroup: DraftFG.packinggroup,
                    inventtoryUnit: 'PCS',
                    saleUnit: "PCS",
                    purchaseUnit: "PCS",
                    raf_journalname: DraftFG.raf_journalname,
                    yymm: "0",
                    swanjob: "1",
                    qtypertag: "1500",
                    jobstatus: DraftFG.jobstatus,
                    tdscode: datajournal.tdscode,
                    journalId: datajournal.journalId
                }

                if (producttype == 'body') {

                    dispatch(addnewitemBody(DraftFG.itemId))
                    // alert(typeitem)
                    if (typeitem == 'NoneW') {
                        setNewitemall({ ...newitemall, proBody: [{ DraftFG, data_objectW: "" }] })

                    }
                    else {
                        setNewitemall({ ...newitemall, proBody: [{ DraftFG, data_objectW }] })

                        if (DraftCE.typeItem == 'CE') {

                            setNewitemall({ ...newitemall, proBody: [{ DraftFG, data_objectW, data_objectCE }] })
                        }
                    }
                }
                else if (producttype == 'top') {

                    dispatch(addnewitemTop(DraftFG.itemId))

                    if (typeitem == 'NoneW') {
                        setNewitemall({ ...newitemall, proTop: [{ DraftFG, data_objectW: "" }] })
                    } else {
                        setNewitemall({ ...newitemall, proTop: [{ DraftFG, data_objectW }] })
                    }


                    if (DraftCE.typeItem == 'CE') {
                        setNewitemall({ ...newitemall, proTop: [{ DraftFG, data_objectW, data_objectCE }] })
                    }

                }
                else if (producttype == 'bottom') {
                    dispatch(addnewitemBottom(DraftFG.itemId))

                    if (typeitem == 'NoneW') {
                        setNewitemall({ ...newitemall, proBottom: [{ DraftFG, data_objectW: "" }] })
                    } else {
                        setNewitemall({ ...newitemall, proBottom: [{ DraftFG, data_objectW }] })
                    }



                    if (DraftCE.typeItem == 'CE') {

                        setNewitemall({ ...newitemall, proBottom: [{ DraftFG, data_objectW, data_objectCE }] })
                    }
                }
                else if (producttype == 'tab') {
                    dispatch(addnewitemTab(DraftFG.itemId))

                    if (typeitem == 'NoneW') {
                        setNewitemall({ ...newitemall, proTab: [{ DraftFG, data_objectW: "" }] })
                    } else {
                        setNewitemall({ ...newitemall, proTab: [{ DraftFG, data_objectW }] })
                    }


                    if (DraftCE.typeItem == 'CE') {
                        console.log(data_objectCE)
                        setNewitemall({ ...newitemall, proTab: [{ DraftFG, data_objectW, data_objectCE }] })
                    }
                }

                //โยนตัวแปลเข้าไปบันทึก
                CreateNewItem(newitemall)
            }
        }
    }

    const CancalProductType = () => {

        if (producttype == 'body') {
            // console.log(DraftFG)
            // console.log(data_objectW)
            dispatch(removenewitemBody(DraftFG.itemId))
            setNewitemall({ ...newitemall, proBody: [] })

            if (DraftCE.typeItem == 'CE') {

                setNewitemall({ ...newitemall, proBody: [] })
            }
        }
        else if (producttype == 'top') {
            dispatch(removenewitemTop(DraftFG.itemId))

            setNewitemall({ ...newitemall, proTop: [] })
            if (DraftCE.typeItem == 'CE') {
                setNewitemall({ ...newitemall, proTop: [] })
            }
        }
        else if (producttype == 'bottom') {
            dispatch(removenewitemBottom(DraftFG.itemId))

            setNewitemall({ ...newitemall, proBottom: [] })
            if (DraftCE.typeItem == 'CE') {

                setNewitemall({ ...newitemall, proBottom: [] })
            }
        }
        else if (producttype == 'tab') {
            dispatch(removenewitemTab(DraftFG.itemId))

            setNewitemall({ ...newitemall, proTab: [] })
            if (DraftCE.typeItem == 'CE') {

                setNewitemall({ ...newitemall, proTab: [] })
            }
        }

        CreateNewItem(newitemall)
    }

    // set data name 


    return (
        <div>
            {DraftFG.length > 0 ? <div>Loading ...</div> : <div>
                <form>
                    <div style={{ backgroundColor: '#E1DDDC', padding: 10 }}>
                        <center>
                            <label style={{ fontSize: 20 }}><b>
                                Draft New Item To ERP
                            </b>
                            </label>
                        </center>
                    </div>
                    <div className='row-page' style={{ width: '100%', marginTop: 20, padding: 10 }}>
                        <div style={{ width: '50%' }}>
                            <div className='row-page'>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Item group </label>
                                </div>
                                <div className='row-page'>
                                    <input type='text' name='itemgroup'
                                        value={groupitem}
                                        style={{ marginRight: 10, height: 25, width: 200 }}
                                        disabled /> <PopUpitemgroup getgroupitem={getgroupitem} />
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Item ID </label>
                                </div>
                                <div>
                                    <input type='text' name='itemId' value={DraftFG.itemId}
                                        onChange={(e) => setDraftFG({ ...DraftFG, itemId: e.target.value })}
                                        style={{ width: 250, height: 25 }} required />
                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Item Name </label>
                                </div>
                                <div>
                                    <input type='text' name='itemName'
                                        value={DraftFG.itemname}
                                        onChange={(e) => setDraftFG({ ...DraftFG, itemname: e.target.value })}
                                        style={{ width: 250, height: 25 }} required />
                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Packing Group </label>
                                </div>
                                <div className='row-page'>
                                    <input type='text' name='PackingGroup'
                                        style={{ marginRight: 10, height: 25, width: 200 }}
                                        value={packinggrouplist}
                                        disabled /> <PopupPackinggroup getPackinggroup={getPackinggroup} />
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>RAF Journal Name</label>
                                </div>
                                <div>
                                    <select name="itemgroup" id="raf_journalname"
                                        onChange={(e) => setDraftFG({ ...DraftFG, raf_journalname: e.target.value })}
                                        style={{ width: 200, height: 25 }} required>
                                        <option value="" selected>เลือกกลุ่ม . . . </option>
                                        <option value="PD-RF2">PD-RF2</option>
                                        <option value="PD-RF3">PD-RF3</option>
                                        <option value="PD-RF4">PD-RF4</option>
                                        <option value="PD-RF5">PD-RF5</option>
                                        <option value="PD-RF6">PD-RF6</option>
                                    </select>
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Serail Number Qty / Tag </label>
                                </div>
                                <div>
                                    <input type='number' name='itemName' value={DraftFG.qtypertag}
                                        onChange={(e) => setDraftFG({ ...DraftFG, qtypertag: e.target.value })}
                                        style={{ width: 250, height: 25 }} required />

                                    <label style={{ padding: 3, margin: 3 }}>ข้อมูลประกอบ ({dataforming.packaging})</label>
                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Type WP / WC </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='radio' name='TypeItem' value={'WP'} onChange={(e) => onhendleTypeItem(e)} defaultValue={'WP'} defaultChecked /> <label>WP</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='TypeItem' value={'WC'} onChange={(e) => onhendleTypeItem(e)} /> <label>WC</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='checkbox' name='TypeItemCE' value={'CE'} onChange={(e) => onhendTypeItemCE(e)} /> <label>CE</label>
                                    </div>

                                </div>
                            </div>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>ไม่สร้าง WC,WP </label>
                                </div>

                                <div style={{ marginLeft: 5, marginRight: 10 }}>

                                    <input type='radio' name='TypeItem' value={'NoneW'} onChange={(e) => onhendleTypeItem(e)} /> <label>NO Create WP,WC</label>
                                    <label>( ไม่สร้าง WC ,WP )</label>

                                </div>

                            </div>




                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Product Type</label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='radio' name='ProTypeItem' value={'body'} onChange={(e) => onhendleProductType(e)} defaultValue={'body'} defaultChecked /> <label>Body</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='ProTypeItem' value={'top'} onChange={(e) => onhendleProductType(e)} /> <label>Top</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='ProTypeItem' value={'bottom'} onChange={(e) => onhendleProductType(e)} /> <label>Bottom</label>
                                    </div>
                                    <div style={{ marginLeft: 5, marginRight: 10 }}>
                                        <input type='radio' name='ProTypeItem' value={'tab'} onChange={(e) => onhendleProductType(e)} /> <label>Tab</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div style={{ width: '50%' }}>

                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Inventory Unit </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='checkbox' name='InventoryUnit' value={'PCS'}
                                            onChange={(e) => onChangInventUnit(e)} defaultValue={'PCS'}
                                            style={{ width: 18, height: 18 }}
                                            defaultChecked disabled /> <label>PCS</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Purchase Unit </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='checkbox' name='PurchaseUnit'
                                            style={{ width: 18, height: 18 }}
                                            value={'PCS'} onChange={(e) => onChangPurchaseUnit(e)}
                                            defaultValue={'PCS'} defaultChecked disabled /> <label>PCS</label>
                                    </div>

                                </div>
                            </div>
                            <div className='row-page' style={{ marginTop: 5 }}>
                                <div style={{ marginRight: 20, width: 200 }}>
                                    <label style={{ fontSize: 15 }}>Sale Unit </label>
                                </div>
                                <div className='row-page' >
                                    <div style={{ marginLeft: 5, marginRight: 15 }}>
                                        <input type='checkbox' name='SaleUnit' value={'PCS'}
                                            onChange={(e) => onChangSaleUnit(e)}
                                            style={{ width: 18, height: 18 }}
                                            defaultValue={'PCS'} defaultChecked disabled /> <label>PCS</label>
                                    </div>

                                </div>
                            </div>

                            <div style={{ marginRight: 20, width: 200, marginTop: 10 }}>
                                <label style={{ fontSize: 15, marginRight: 10 }}>ยืนยันการสร้าง </label>
                                <input type='checkbox' name='SaleUnit' value={'1'} onChange={(e) => CheckItemConfirm(e)} defaultValue={'1'} defaultChecked />
                            </div>

                        </div>
                    </div>
                    <hr></hr>


                    <div className='row-page' style={{ alignContent: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                        <div>
                            <Button onClick={() => savedraftNewitem()} style={{ marginRight: 10 }} size='sm'>ร่างเอกสาร</Button>
                        </div>
                        <div>
                            <Button variant="warning" size='sm' onClick={() => CancalProductType()}>ลบร่างเอกสาร</Button>
                        </div>

                    </div>

                </form>
                <div style={{ marginTop: 5 }}>


                    {newitemall.proBody.length == 1 &&

                        newitemall.proBody.map((item, i) => (
                            <TableINewitem datatable={item} />
                        ))

                    }

                    {newitemall.proTop.length == 1 &&

                        newitemall.proTop.map((item, i) => (
                            <TableINewitem datatable={item} />
                        ))
                    }

                    {newitemall.proBottom.length == 1 &&

                        newitemall.proBottom.map((item, i) => (
                            <TableINewitem datatable={item} />
                        ))
                    }

                    {newitemall.proTab.length == 1 &&

                        newitemall.proTab.map((item, i) => (
                            <TableINewitem datatable={item} />
                        ))
                    }


                </div>

            </div>}

            <div>

            </div>
        </div>
    )
}

export default CreateNewitemToerp