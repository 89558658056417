import React from 'react'
import DataTable from 'react-data-table-component';
import Recievedjournal from './recievedjournal';



const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => row.tdscode,
        grow: 0.5,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
        grow: 0.5,
    },
    {
        name: 'customer name',
        selector: row => row.customername,
    },
    {
        name: 'product name',
        sortable: true,
        selector: row => row.productname,
    },
    {
        name: 'revision',
        selector: row => row.revision,
        grow: 0.3,
    },
    // {
    //     name: 'product name',
    //     sortable: true,
    //     selector: row => row.productname,
    // },
    {
        name: 'last film code',
        sortable: true,
        selector: row => row.filmcode,
        grow: 1,
    },
    {
        name: 'createdate',
        selector: row => row.createdate,
        grow: 0.5,
    },
    {
        name: 'Action',
        grow: 0.5,
        cell: row => <Recievedjournal Id={row.journalId} stateflow={row.stateflow} statusflow={row.statusflow}/>,
    },

];

//tasknote

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Tablejournallistrecieved = ({ values }) => {
    var data = values;
    return (
        <DataTable
            columns={columns}
            data={data}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tablejournallistrecieved