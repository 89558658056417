import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';

import dataform from '../../../form01/json-data/datatypeform.json';
import {
  objectcoatingbody, objectcoatingtop,
  objectcoatingbottom, objectcoatingring, objectcoatingear, opjectHeader, statusflowall
} from '../../../form01/object-usestate/objectdefaults';
import Swal from 'sweetalert2'

import SearchWhitecode from './searchWhitecode';


function EditJournal({ id }) {

  const FetchApis = new FetchApi();

  const emCode = sessionStorage.getItem('EmCode');

  const [coatingbody, setCoatingbody] = useState({ ...objectcoatingbody });
  const [coatingtop, setCoatingtop] = useState({ ...objectcoatingtop });
  const [coatingbottom, setCoatingbottom] = useState({ ...objectcoatingbottom });
  const [coatingring, setCoatingring] = useState({ ...objectcoatingring });
  const [coatingear, setCoatingear] = useState({ ...objectcoatingear });


  const [filmtypeError, setfilmtypeError] = useState(false);


  const [journalHead, setJournalhead] = useState({ ...opjectHeader });

  useEffect(() => {
    getJournalHearder();
  }, [])


  const getJournalHearder = () => {
    const { filmtype, printingtype, printingmachine } = journalHead
    FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((res) => {

      var groupprintingmachine = res.data.data[0].printingmachine.split(",");
      console.log(groupprintingmachine)

      if (res.data.status == 200) {
        getCoatingbody(res.data.data[0].journalId);


      }

      setJournalhead({
        ...res.data.data[0],
        filmtype: [...filmtype, res.data.data[0].filmtype],
        printingtype: [...printingtype, res.data.data[0].printingtype],
        printingmachine: groupprintingmachine
      })

    })
  }

  async function getCoatingbody(Id) {

    await FetchApis.FethcGet(`/coating/coatingByJournalId/${Id}`).then((res) => {
      if (res.data.status == 200) {
        const { groupBobyType, intcoating, extcoating, exttypewhite, statusProof } = coatingbody;

        for (let index = 0; index < res.data.data.length; index++) {

          if (res.data.data[index].producttype == 'body') {
            setCoatingbody({
              ...res.data.data[index],
              groupBobyType: [...groupBobyType, res.data.data[index].groupBobyType],
              intcoating: [...intcoating, res.data.data[index].intcoating],
              extcoating: [...extcoating, res.data.data[index].extcoating],
              exttypewhite: [...exttypewhite, res.data.data[index].exttypewhite]

            })
          }

        }
      }
    })


    await FetchApis.FethcGet(`/coating/coatingByJournalId/${Id}`).then((res) => {
      if (res.data.status == 200) {
        const { substrate, shape, intcoating, extcoating, exttypewhite } = coatingtop;

        for (let index = 0; index < res.data.data.length; index++) {

          if (res.data.data[index].producttype == 'top') {
            setCoatingtop({
              ...res.data.data[index],
              substrate: [...substrate, res.data.data[index].substrate],
              shape: [...shape, res.data.data[index].shape],
              intcoating: [...intcoating, res.data.data[index].intcoating],
              extcoating: [...extcoating, res.data.data[index].extcoating],
              exttypewhite: [...exttypewhite, res.data.data[index].exttypewhite]
            })
          }
        }
      }
    })


    await FetchApis.FethcGet(`/coating/coatingByJournalId/${Id}`).then((res) => {
      if (res.data.status == 200) {
        const { substrate, shape, intcoating, extcoating, exttypewhite } = coatingbottom;

        for (let index = 0; index < res.data.data.length; index++) {

          if (res.data.data[index].producttype == 'bottom') {
            setCoatingbottom({
              ...res.data.data[index],
              substrate: [...substrate, res.data.data[index].substrate],
              shape: [...shape, res.data.data[index].shape],
              intcoating: [...intcoating, res.data.data[index].intcoating],
              extcoating: [...extcoating, res.data.data[index].extcoating],
              exttypewhite: [...exttypewhite, res.data.data[index].exttypewhite]
            })
          }
        }
      }
    })

    await FetchApis.FethcGet(`/coating/coatingByJournalId/${Id}`).then((res) => {
      if (res.data.status == 200) {
        const { ring_eartype, groupBobyType, intcoating, extcoating, exttypewhite } = coatingring;


        for (let index = 0; index < res.data.data.length; index++) {

          if (res.data.data[index].producttype == 'ring') {

            setCoatingring({
              ...res.data.data[index],
              groupBobyType: [...groupBobyType, res.data.data[index].groupBobyType],
              ring_eartype: [...ring_eartype, res.data.data[index].ring_eartype],
              intcoating: [...intcoating, res.data.data[index].intcoating],
              extcoating: [...extcoating, res.data.data[index].extcoating],
              exttypewhite: [...exttypewhite, res.data.data[index].exttypewhite]
            })
          }
        }
      }
    })
    await FetchApis.FethcGet(`/coating/coatingByJournalId/${Id}`).then((res) => {
      if (res.data.status == 200) {
        const { ring_eartype, groupBobyType, intcoating, extcoating, exttypewhite } = coatingear;


        for (let index = 0; index < res.data.data.length; index++) {

          if (res.data.data[index].producttype == 'ear') {
            setCoatingear({
              ...res.data.data[index],
              groupBobyType: [...groupBobyType, res.data.data[index].groupBobyType],
              ring_eartype: [...ring_eartype, res.data.data[index].ring_eartype],
              intcoating: [...intcoating, res.data.data[index].intcoating],
              extcoating: [...extcoating, res.data.data[index].extcoating],
              exttypewhite: [...exttypewhite, res.data.data[index].exttypewhite]
            })
          }
        }
      }
    })
  }
  // check body ... 
  const handle_bodychecked = (e) => {

    const { value, checked } = e.target;
    const { groupBobyType, intcoating, extcoating } = coatingbody;

    if (checked) {
      if (e.target.name == "groupBobyType") {

        if (groupBobyType[0] == '') {

          var cusvalue = groupBobyType[0] = value;
          setCoatingbody({
            ...coatingbody,
            groupBobyType: [cusvalue],
          });
        }
        else {
          setCoatingbody({
            ...coatingbody,
            groupBobyType: [...groupBobyType, value],
          });
        }

      }

      else if (e.target.name == "intcoatingbody") {
        if (intcoating[0] == '') {
          var invalue = intcoating[0] = value;
          setCoatingbody({
            ...coatingbody,
            intcoating: [invalue],
          });
        }
        else {
          setCoatingbody({
            ...coatingbody,
            intcoating: [...intcoating, value],
          });
        }

      }
      else if (e.target.name == "extcoatingbody") {

        if (extcoating[0] == '') {

          var exvalue = extcoating[0] = value;
          setCoatingbody({
            ...coatingbody,
            extcoating: [exvalue],
          });
        } else {
          setCoatingbody({
            ...coatingbody,
            extcoating: [...extcoating, value],
          });
        }


      }
      else if (e.target.name == 'bodyProof') {
        setCoatingbody({
          ...coatingbody,
          statusProof: value,
        });
      }
    }
    // Case 2 : The user unchecks the box
    else {
      if (e.target.name == 'groupBobyType') {
        setCoatingbody({
          ...coatingbody,
          groupBobyType: groupBobyType.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "intcoatingbody") {
        setCoatingbody({
          ...coatingbody,
          intcoating: intcoating.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "extcoatingbody") {
        setCoatingbody({
          ...coatingbody,
          extcoating: extcoating.filter((e) => e !== value),
        });
      }
      else if (e.target.name == 'bodyProof') {
        setCoatingbody({
          ...coatingbody,
          statusProof: "",
        });
      }
    }

  }

  //check top  ...
  const handle_topchecked = (e) => {
    const { value, checked } = e.target;
    const { substrate, shape, intcoating, extcoating } = coatingtop;

    if (checked) {
      if (e.target.name == "substratetop") {
        if (substrate[0] == '') {
          var subvalue = substrate[0] = value;
          setCoatingtop({
            ...coatingtop,
            substrate: [subvalue],
          });
        } else {
          setCoatingtop({
            ...coatingtop,
            substrate: [...substrate, value],
          });
        }

      }
      else if (e.target.name == "Shapetop") {

        if (shape[0] == '') {
          var shapevalue = shape[0] = value;
          setCoatingtop({
            ...coatingtop,
            shape: [shapevalue],
          });
        } else {
          setCoatingtop({
            ...coatingtop,
            shape: [...shape, value],
          });
        }

      }
      else if (e.target.name == "intcoatingtop") {

        if (intcoating[0] == '') {
          var intcoatingvalue = intcoating[0] = value;
          setCoatingtop({
            ...coatingtop,
            intcoating: [intcoatingvalue],
          });
        } else {
          setCoatingtop({
            ...coatingtop,
            intcoating: [...intcoating, value],
          });
        }

      }
      else if (e.target.name == "extcoatingtop") {
        if (extcoating[0] == '') {
          var extcoatingvalue = extcoating[0] = value;
          setCoatingtop({
            ...coatingtop,
            extcoating: [extcoatingvalue],
          });
        } else {
          setCoatingtop({
            ...coatingtop,
            extcoating: [...extcoating, value],
          });
        }


      }
      else if (e.target.name == 'topProof') {
        setCoatingtop({
          ...coatingtop,
          statusProof: value,
        });
      }
    }
    else {
      if (e.target.name == 'substratetop') {
        setCoatingtop({
          ...coatingtop,
          substrate: substrate.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "Shapetop") {

        setCoatingtop({
          ...coatingtop,
          shape: shape.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "intcoatingtop") {
        setCoatingtop({
          ...coatingtop,
          intcoating: intcoating.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "extcoatingtop") {
        setCoatingtop({
          ...coatingtop,
          extcoating: extcoating.filter((e) => e !== value),
        });
      }
      else if (e.target.name == 'topProof') {
        setCoatingtop({
          ...coatingtop,
          statusProof: "",
        });
      }
    }
  }

  //  check bottom
  const handle_bottomchecked = (e) => {

    const { value, checked } = e.target;
    const { substrate, shape, intcoating, extcoating } = coatingbottom;
    if (checked) {
      if (e.target.name == "substratebottom") {

        if (substrate[0] == '') {
          var subvalue = substrate[0] = value;
          setCoatingbottom({
            ...coatingbottom,
            substrate: [subvalue],
          });
        } else {
          setCoatingbottom({
            ...coatingbottom,
            substrate: [...substrate, value],
          });
        }
      }
      else if (e.target.name == "Shapebottom") {

        if (shape[0] == '') {
          var shapevalue = shape[0] = value;
          setCoatingbottom({
            ...coatingbottom,
            shape: [shapevalue],
          });
        } else {
          setCoatingbottom({
            ...coatingbottom,
            shape: [...shape, value],
          });
        }
      }
      else if (e.target.name == "intcoatingbottom") {

        if (intcoating[0] == '') {
          var intcoatingvalue = intcoating[0] = value;
          setCoatingbottom({
            ...coatingbottom,
            intcoating: [intcoatingvalue],
          });
        } else {
          setCoatingbottom({
            ...coatingbottom,
            intcoating: [...intcoating, value],
          });
        }
      }
      else if (e.target.name == "extcoatingbottom") {

        if (extcoating[0] == '') {
          var extcoatingvalue = extcoating[0] = value;
          setCoatingbottom({
            ...coatingbottom,
            extcoating: [extcoatingvalue],
          });
        } else {
          setCoatingbottom({
            ...coatingbottom,
            extcoating: [...extcoating, value],
          });
        }
      }
      else if (e.target.name == 'bottomProof') {
        setCoatingbottom({
          ...coatingbottom,
          statusProof: value,
        });
      }
    }
    else {
      if (e.target.name == 'substratebottom') {
        setCoatingbottom({
          ...coatingbottom,
          substrate: substrate.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "Shapebottom") {
        setCoatingbottom({
          ...coatingbottom,
          shape: shape.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "intcoatingbottom") {
        setCoatingbottom({
          ...coatingbottom,
          intcoating: intcoating.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "extcoatingbottom") {
        setCoatingbottom({
          ...coatingbottom,
          extcoating: extcoating.filter((e) => e !== value),
        });
      }
      else if (e.target.name == "bottomProof") {
        setCoatingbottom({
          ...coatingbottom,
          statusProof: "",
        });
      }

    }
  }
  // checkbox coating  ring 
  const handle_Ringchecked = (e) => {
    const { value, checked } = e.target;
    const { ring_eartype, intcoating, extcoating } = coatingring;

    if (checked) {
      if (e.target.name == 'coatingDia') {
        setCoatingring({
          ...coatingring,
          ring_eartype: [...ring_eartype, value]
        })
      } else if (e.target.name == 'intcoatingring') {
        setCoatingring({
          ...coatingring,
          intcoating: [...intcoating, value]
        })
      }
      else if (e.target.name == "extcoatingring") {
        setCoatingring({
          ...coatingring,
          extcoating: [...extcoating, value]
        })
      } else if (e.target.name == 'ringProof') {
        setCoatingring({
          ...coatingring,
          statusProof: value
        })
      }

    }
    else {
      if (e.target.name == 'coatingDia') {
        setCoatingring({
          ...coatingring,
          ring_eartype: ring_eartype.filter((e) => e !== value)
        })

      } else if (e.target.name == 'intcoatingring') {
        setCoatingring({
          ...coatingring,
          intcoating: intcoating.filter((e) => e !== value)
        })
      }
      else if (e.target.name == "extcoatingring") {
        setCoatingring({
          ...coatingring,
          extcoating: extcoating.filter((e) => e !== value)
        })
      } else if (e.target.name == 'ringProof') {
        setCoatingring({
          ...coatingring,
          statusProof: ""
        })
      }
    }
  }
  // check box coating ear
  const handle_earCheck = (e) => {
    const { value, checked } = e.target;
    const { ring_eartype, intcoating } = coatingear;
    if (checked) {
      if (e.target.name == 'eartype') {
        setCoatingear({
          ...coatingear,
          ring_eartype: [...ring_eartype, value]
        })
      }
      else if (e.target.name == 'intcoatingear') {
        setCoatingear({
          ...coatingear,
          intcoating: [...intcoating, value]
        })
      }
      else if (e.target.name == 'earProof') {
        setCoatingear({
          ...coatingear,
          statusProof: value
        })
      }

    }
    else {

      if (e.target.name == 'eartype') {
        setCoatingear({
          ...coatingear,
          ring_eartype: ring_eartype.filter((e) => e !== value)
        })
      } else if (e.target.name == 'intcoatingear') {
        setCoatingear({
          ...coatingear,
          intcoating: intcoating.filter((e) => e !== value)
        })
      }
      else if (e.target.name == 'earProof') {
        setCoatingear({ ...coatingear, statusProof: "" })
      }
    }
  }

  function handleChange(e) {

    const { value, checked } = e.target;
    const { filmtype, printingtype } = journalHead

    if (checked) {
      if (e.target.name == 'printingtype') {
        // console.log(value);
        if (printingtype[0] == 'false' || printingtype[0] == "") {
          setJournalhead({
            ...journalHead,
            printingtype: [value]
          })
        } else {
          setJournalhead({
            ...journalHead,
            printingtype: [...printingtype, value]
          })

        }

      }
      else if (e.target.name == 'namefilmtype') {

        setJournalhead({
          ...journalHead,
          filmtype: [...filmtype, value]
        })

      }

    }
    else {

      if (e.target.name == 'printingtype') {
        setJournalhead({
          ...journalHead, printingtype: printingtype.filter((e) => e !== value)
        })

      }
      else if (e.target.name == 'namefilmtype') {
        setJournalhead({
          ...journalHead, filmtype: filmtype.filter((e) => e !== value)
        })
      }
    }
  };


  function ChengeMachine(e) {
    const { printingmachine } = journalHead
    let checkvalue = e.target.checked;
    let values = e.target.value;

    if (checkvalue) {
      if (e.target.name == 'printingmachine') {

        if (printingmachine[0] == "") {
          setJournalhead({
            ...journalHead,
            printingmachine: [values]
          })

        } else {
          setJournalhead({
            ...journalHead,
            printingmachine: [...printingmachine, values]
          })

        }

      }
    } else {

      if (e.target.name == 'printingmachine') {
        setJournalhead({
          ...journalHead, printingmachine: printingmachine.filter((e) => e !== values)
        })
      }

    }

  }


  async function handle_onSave() {

    if (coatingbody.exttypewhite.length > 1) {
      alert('exttype white  body มากกว่า 1 รายก กรุณาระบุให้ถูกต้อง')
      return;
    }
    else if (coatingtop.exttypewhite.length > 1) {
      alert('exttype white  Top มากกว่า 1 รายก กรุณาระบุให้ถูกต้อง')
      return;
    }
    else if (coatingbottom.exttypewhite.length > 1) {
      alert('exttype white  bottom มากกว่า 1 รายก กรุณาระบุให้ถูกต้อง')
      return;
    }
    else if (coatingring.exttypewhite.length > 1) {
      alert('exttype white  ring มากกว่า 1 รายก กรุณาระบุให้ถูกต้อง')
      return;
    }


    if (journalHead.printingtype.length >= 2) {

      Swal.fire({
        title: "แจ้งเตือน!",
        text: "คุณเลือกข้อมูล printing  system เกิน 2 รายการ",
        icon: "error"
      });
    }
    else {
      if (journalHead.printingmachine.length >= 5) {
        Swal.fire({
          title: "แจ้งเตือน!",
          text: "คุณเลือกข้อมูล printing  machine เกิน 2 รายการ",
          icon: "error"
        });
      } else {
        if (journalHead.filmtype.length >= 2) {
          Swal.fire({
            title: "แจ้งเตือน!",
            text: "กรุณาเลือก scrap หรือ maintain ไม่เกิน 2 รายการ",
            icon: "error"
          });
        } else {

          Swal.fire({
            title: "แจ้งเตือน",
            text: "คุณแน่ใจบันทึกเอกสาร ใช่หรือไม่ ! ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: 'ยกเลิก'
          }).then((result) => {

            if (result.isConfirmed) {
              Save_update();

              Swal.fire({
                title: "อนุมัติเอกสาร!",
                text: "อนุมัติเอกสารเรียบแล้ว",
                icon: "success"
              });

            }
          })
        }
      }
    }

  }

  async function Save_update() {

    await FetchApis.FethcUpdate(`/journal/journalupdate/${journalHead.journalId}`, journalHead).then((result) => {
      if (result) {
      }
    }).catch((err) => {
      console.log(err)
    });


    //update type body
    await FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingbody.coatingId}`, coatingbody).then((res) => {

      if (res.status != 200) {
        alert(res.message)
      } else {
        // console.log(coatingtop)
      }
    }).catch((err) => { console.log(err) })

    //update coating type top
    await FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingtop.coatingId}`, coatingtop).then((res) => {

      if (res.status != 200) {
        alert(res.message)
      } else {
        // console.log(coatingtop)
      }
    }).catch((err) => { console.log(err) })

    //   //update coating type bottom
    await FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingbottom.coatingId}`, coatingbottom).then((res) => {

      if (res.status != 200) {
        alert(res.message)
      }
    }).catch((err) => { console.log(err) })

    //   //update coating type ring
    await FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingring.coatingId}`, coatingring).then((res) => {

      if (res.status != 200) {
        alert(res.message)
      }
    }).catch((err) => { console.log(err) })

    //   //update coating type ear
    await FetchApis.FethcUpdate(`/coating/coatingUpdateById/${coatingear.coatingId}`, coatingear).then((res) => {

      if (res.status != 200) {
        alert(res.message)
      }
    }).catch((err) => { console.log(err) })

  }




  function onSave_successfully() {

    if (journalHead.printingtype.length >= 2) {

      Swal.fire({
        title: "แจ้งเตือน!",
        text: "คุณเลือกข้อมูล printing  system เกิน 2 รายการ",
        icon: "error"
      });

    } else {

      Swal.fire({
        title: "แจ้งเตือน",
        text: "คุณแน่ใจบันทึกเอกสาร ใช่หรือไม่ ! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: 'ยกเลิก'
      }).then((result) => {

        if (result.isConfirmed) {
          //update coating
          Save_update();
          //update journal header
          recievedjournal()
        }
      });

    }

  }

  // update  recieved 
  function recievedjournal() {

    var dataint = parseInt(journalHead.stateflow) + 1; //อัพเดท journal

    var statejournal = parseInt(journalHead.stateflow) //อัพเดท taskflow
    var dataobject = {
      journalid: id,
      emcode: emCode,
      stateflow: statejournal,
      approvecode: statusflowall.approvejournal,
      dedicatecode: "",
      tasknote: ""
    }
    // // create task flow
    FetchApis.FethcPost(`/taskflow/createTaskflow`, dataobject).then((res) => {
      if (res.status == 200) {
        updatejournal(dataint);
      }
    })
  }

  // //update flowstatus journal
  function updatejournal(stateflows) {

    var updatejournal = {
      statusflow: statusflowall.sendjournal,
      stateflow: stateflows
    }

    // setNewjournalUpdate(updatejournal);
    FetchApis.FethcUpdate(`/journal/updatestateflow/${id}`, updatejournal).then((res) => {
      if (res.status == 200) {

        Swal.fire({
          title: "อนุมัติเอกสาร!",
          text: "อนุมัติเอกสารเรียบแล้ว",
          icon: "success"
        });

        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    })

  }

  // proof status  job 
  function jobchangeproofjob(e) {
    var valuechecked = e.target.checked;

    var { name, value } = e.target;

    if (valuechecked == true) {

      setJournalhead({ ...journalHead, statusprove_Tds: value })
    } else {
      setJournalhead({ ...journalHead, statusprove_Tds: "0" })

    }

  }

  function whitecodeValue(values, sequence) {

    if (sequence == "1") {
      setCoatingbody({ ...coatingbody, extwhite: values })
    }
    else if (sequence == "2") {
      setCoatingtop({ ...coatingtop, extwhite: values })
    }
    else if (sequence == "3") {
      setCoatingbottom({ ...coatingbottom, extwhite: values })
    }
    else if (sequence == "4") {
      setCoatingring({ ...coatingring, extwhite: values })
    }
    else if (sequence == "5") {
      setCoatingear({ ...coatingear, extwhite: values })
    }
  }

  function getGroup(vl) {

    var checkitem = false
    var data = journalHead.printingmachine.filter((fid) => fid == vl)

    return data
  }


  async function Bodyexttypewhite(e) {
    const { exttypewhite } = coatingbody;
    var valuechecked = e.target.checked;

    var { name, value } = e.target;
    if (valuechecked) {
      if (exttypewhite[0] == '') {
        var valuebody = exttypewhite[0] = value;
        setCoatingbody({
          ...coatingbody,
          exttypewhite: [valuebody]
        })
      } else {
        setCoatingbody({ ...coatingbody, exttypewhite: [...exttypewhite, value] })
      }

    } else {
      setCoatingbody({
        ...coatingbody, exttypewhite: exttypewhite.filter((e) => e !== value)
      })
    }

  }

  async function Tobexttypewhite(e) {

    const { exttypewhite } = coatingtop;
    var valuechecked = e.target.checked;

    var { name, value } = e.target;
    if (valuechecked) {

      if (exttypewhite[0] == '') {
        var valuebody = exttypewhite[0] = value;
        setCoatingtop({
          ...coatingtop,
          exttypewhite: [valuebody]
        })
      } else {
        setCoatingtop({ ...coatingtop, exttypewhite: [...exttypewhite, value] })
      }

    } else {
      setCoatingtop({
        ...coatingtop, exttypewhite: exttypewhite.filter((e) => e !== value)
      })
    }

  }

  async function bottomexttypewhite(e) {
    const { exttypewhite } = coatingbottom;
    var valuechecked = e.target.checked;

    var { name, value } = e.target;
    if (valuechecked) {

      if (exttypewhite[0] == '') {
        var valuebody = exttypewhite[0] = value;
        setCoatingbottom({
          ...coatingbottom,
          exttypewhite: [valuebody]
        })
      } else {
        setCoatingbottom({ ...coatingbottom, exttypewhite: [...exttypewhite, value] })
      }


    } else {
      setCoatingbottom({
        ...coatingbottom, exttypewhite: exttypewhite.filter((e) => e !== value)
      })
    }
  }

  async function ringexttypewhite(e) {
    const { exttypewhite } = coatingring;
    var valuechecked = e.target.checked;

    var { name, value } = e.target;
    if (valuechecked) {
     
      if (exttypewhite[0] == '') {
        var valuebody = exttypewhite[0] = value;
        setCoatingring({
          ...coatingring,
          exttypewhite: [valuebody]
        })
      } else {
        setCoatingring({ ...coatingring, exttypewhite: [...exttypewhite, value] })
      }

    } else {
      setCoatingring({
        ...coatingring, exttypewhite: exttypewhite.filter((e) => e !== value)
      })
    }
  }



  return (
    <div className='form-body-page'>
      {journalHead.length > 0 ? <div></div> : <div>


        <div className='end-flex-row '>
          <div style={{ marginRight: 5 }}>
            <Button size='sm' variant="success" onClick={() => onSave_successfully()}>อนุมัติ</Button>
          </div>
          <div style={{ marginRight: 5 }}>
            <Button size='sm' variant="primary" onClick={() => handle_onSave()}>บันทึก</Button>
          </div>
          <div style={{ marginRight: 5 }}>
            <Button size='sm' variant="warning">ยกเลิก</Button>
          </div>
        </div>
        <div>
          <div>
            <table style={{ width: '100%' }} >
              <tr>
                <th><center>Create product</center></th>
                <th><center>Due Date</center></th>
                <th><center>MK NO</center></th>
                <th><center>Date Cretae</center></th>
              </tr>
              <tr>
                <th style={{ paddingLeft: 5 }}>{journalHead.journaltype}</th>
                <th style={{ paddingLeft: 5 }}>{journalHead.duedate}</th>
                <th style={{ paddingLeft: 5 }}>{journalHead.tdscode}</th>
                <th style={{ paddingLeft: 5 }}>{journalHead.createdate}</th>
              </tr>
            </table>
          </div>
          <div style={{ width: '100%' }} className='row-between-form'>
            <div style={{ width: '50%' }} className='border_green'>
              <table style={{ width: '100%' }} >
                <tr>
                  <td style={{ width: '30%' }}>Product name : </td>
                  <td>{journalHead.productname}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>Can Size /Dia : </td>
                  <td> {journalHead.cansize}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>Item name : </td>
                  <td>{journalHead.itemname}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>Item NO : </td>
                  <td>{journalHead.itemcode}</td>
                </tr>
              </table>
            </div>
            <div style={{ width: '50%', marginLeft: 3 }} className='border_green'>
              <table style={{ width: '100%' }} >
                <tr>
                  <td style={{ width: '30%' }}>Customer name : </td>
                  <td>{journalHead.customername}</td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>Contact : </td>
                  <td>{journalHead.contact} </td>
                </tr>
                <tr>
                  <td style={{ width: '30%' }}>Tel : </td>
                  <td>{journalHead.tel} </td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <table style={{ width: '100%' }}>
              <tr>
                <th>Substrate</th>
                <th>Product group</th>
                <th>Information from customer</th>
                <th>Note Comment</th>
              </tr>
              <tr>
                <td><input type='checkbox' checked={true} disabled /> {journalHead.substratetype}</td>
                <td><input type='checkbox' checked={true} disabled /> {journalHead.productgrouptype}</td>
                <td><input type='checkbox' checked={true} disabled /> {journalHead.informationtype == 'false' ? '! ไม่ได้ระบุ ข้อมูล' : journalHead.informationtype}</td>
                <td style={{ width: '50%' }}>
                  <tr>
                    <div className='border_green' style={{ width: '100%' }}>
                      <div style={{ margin: 10 }}>
                        <label><b>STATUS PROOF  JOB </b></label>
                      </div>
                      <div className='row-page'>
                        <div style={{ marginRight: 50 }}><b>PROOF JOB</b> :
                          {journalHead.statusprove_Tds == 1 ? <input type='checkbox' name='statusprove_Tds' value={1} defaultChecked={true}
                            onClick={(e) => jobchangeproofjob(e)} /> : <input type='checkbox' name='statusprove_Tds' value={1}
                              onClick={(e) => jobchangeproofjob(e)} />}
                        </div>
                        <div>
                          <div><label><b>NOTE</b></label></div>
                          <div>
                            <textarea style={{ width: 500, height: 45 }}
                              value={journalHead.noteapproveTds}
                              onChange={(e) => setJournalhead({ ...journalHead, noteapproveTds: e.target.value })}>
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tr>

                </td>
              </tr>
            </table>


            <table style={{ width: '100%' }}>
              <tr style={{ width: '100%' }}>
                <th>Printing System</th>
              </tr>
              <tr>
                <td>
                  <div>
                    <div className='row-page'>
                      <div className='border_green row-page' style={{ width: '50%', padding: 10, marginRight: 10 }}>
                        <div style={{ width: '30%' }}>
                          <b>Printing System ( Ink )</b>
                          {dataform.printing.data.map((item, i) => (
                            <div>
                              {
                                journalHead.printingtype == item ?
                                  <div>

                                    <input type='checkbox' id='printingtype' name='printingtype'
                                      onClick={(e) => handleChange(e)} defaultChecked={true} value={item} /> {item}
                                  </div> :
                                  <div>
                                    <input type='checkbox' name='printingtype' onClick={(e) => handleChange(e)} value={item} /> {item}
                                  </div>
                              }
                            </div>
                          ))}
                        </div>

                        <div style={{ width: '30%' }}>
                          <b>Printing System ( Ink )</b>
                          {dataform.printing.data2.map((item, i) => (
                            <div>
                              {
                                journalHead.printingtype == item ?
                                  <div>

                                    <input type='checkbox' id='printingtype' name='printingtype'
                                      onClick={(e) => handleChange(e)} defaultChecked={true} value={item} /> {item}
                                  </div> :
                                  <div>
                                    <input type='checkbox' name='printingtype' onClick={(e) => handleChange(e)} value={item} /> {item}
                                  </div>
                              }
                            </div>
                          ))}
                        </div>
                        <div>
                          <textarea type='text' name='printingtypeother' placeholder='Input other'
                            value={journalHead.printingtypeother}
                            onChange={(e) => setJournalhead({ ...journalHead, printingtypeother: e.target.value })}
                            style={{ width: 300 }}
                          />
                        </div>
                      </div>

                      <div style={{ width: '30%' }} className='border_green'>
                        <b>Printing Machine</b>
                        {dataform.printing.datamachine.map((item, i) => (
                          <div>

                            {
                              getGroup(item) == item ?
                                <div>
                                  <input type='checkbox' id='printingmachine' name='printingmachine'
                                    onClick={(e) => ChengeMachine(e)} defaultChecked={true} value={item} /> {item}
                                </div> :
                                <div>
                                  <input type='checkbox' name='printingmachine' onClick={(e) => ChengeMachine(e)} value={item} /> {item}
                                </div>
                            }
                          </div>
                        ))}
                      </div>

                    </div>
                    <hr></hr>
                    <div className='row-page'>
                      <div>
                        <b>Last film code :</b><input type='text' id='filmcode'
                          value={journalHead.filmcode}
                          onChange={(e) => setJournalhead({ ...journalHead, filmcode: e.target.value })} disabled />
                      </div>

                      <div className='row-page'>
                        <b>Ref. Item FG : </b>

                        <input type='text' value={journalHead.refFgcode} disabled />
                      </div>
                    </div>

                    <div>
                      {journalHead.filmtype == "Scrap" ? <div>
                        <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Scrap"}
                          onClick={(e) => handleChange(e)}
                          defaultChecked={true}
                        /> Scrap </div> :
                        <div>
                          <input type='checkbox' id="filmtype" name='namefilmtype' value={"Scrap"}
                            onClick={(e) => handleChange(e)}

                          /> Scrap </div>}

                      {
                        journalHead.filmtype == "Maintain" ? <div>
                          <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintain"}
                            onClick={(e) => handleChange(e)}

                            defaultChecked={true}

                          /> Maintain
                        </div> :
                          <div>
                            <input type='checkbox' id="namefilmtype" name='namefilmtype' value={"Maintain"}
                              onClick={(e) => handleChange(e)}

                            /> Maintain
                          </div>
                      }
                      {filmtypeError == true ? <label style={{ color: 'red' }}>'กรุณาเลือก 1 รายการเท่านั้น '</label> : ""}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <hr></hr>
          <div>
            <div style={{ marginLeft: 10 }}><b>Coating system</b></div>

            <div>
              <div className='row-page' w style={{ padding: 10 }}>

                <div style={{ width: 100 }}><b>Body</b></div>

                <div style={{ width: "50%" }}>

                  <div className='row-between-form'>
                    {dataform.coating.body.data.map((item, i) => (

                      <div>{coatingbody.groupBobyType != item ?
                        <div><input type='checkbox' name='groupBobyType' value={item}
                          onClick={(e) => handle_bodychecked(e)}
                          disabled
                        /> {item}
                        </div> :
                        <div>
                          <input type='checkbox' name='groupBobyType' value={item}
                            defaultChecked={true}
                            onClick={(e) => handle_bodychecked(e)}
                            disabled
                          /> {item}
                        </div>
                      }
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ width: "90%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                <div className='row-between-form'>
                  <b>Int {" : "}</b>
                  {dataform.coating.int.data.map((item, i) => (
                    <div>
                      {item == 'white-coat' ? <div>
                        <input type='text' name='intwhitebody'
                          value={coatingbody.intwhite}
                          placeholder={item}
                          onChange={(e) => setCoatingbody({ ...coatingbody, intwhite: e.target.value })}
                          disabled
                        />
                      </div> :
                        <div>
                          {coatingbody.intcoating == item ?
                            <div>
                              <input type='checkbox' name='intcoatingbody' value={item}
                                onClick={(e) => handle_bodychecked(e)}
                                defaultChecked={true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='intcoatingbody' value={item}
                                onClick={(e) => handle_bodychecked(e)}
                                disabled
                              /> {item}
                            </div>}

                        </div>

                          && item == 'Other' ? <div>
                          <input type='text' name='intotherbody'
                            value={coatingbody.intother} placeholder={item}
                            onChange={(e) => setCoatingbody({ ...coatingbody, intother: e.target.value })}
                            disabled
                          />
                        </div> :
                          <div>
                            {coatingbody.intcoating == item ?
                              <div>
                                <input type='checkbox' name='intcoatingbody' value={item}
                                  onClick={(e) => handle_bodychecked(e)}
                                  defaultChecked={true}
                                  disabled
                                /> {item}
                              </div> :
                              <div>
                                <input type='checkbox' name='intcoatingbody'
                                  value={item}
                                  onClick={(e) => handle_bodychecked(e)}
                                  disabled
                                /> {item}
                              </div>}
                          </div>
                      }</div>
                  ))}
                </div>

                <div className='row-between-form'>
                  <b>Ext {" : "}</b>
                  {dataform.coating.ext.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div>

                      <div >
                        <div className='row-page'>
                          <input type='text' name='extwhitebody' placeholder={item} value={coatingbody.extwhite}
                            onChange={(e) => setCoatingbody({ ...coatingbody, extwhite: e.target.value })} style={{ width: 200 }} />
                          <SearchWhitecode whitecodeValue={whitecodeValue} sequence={"1"} />
                        </div>

                        <div className='row-page'>
                          <div style={{ marginRight: 10 }}>
                            {/* {JSON.stringify(coatingbody.exttypewhite)} */}
                            {coatingbody.exttypewhite == 'white-coat' ? <div>
                              <input type='checkbox' name='whitecoatbody' value={'white-coat'}
                                onClick={(e) => Bodyexttypewhite(e)} defaultChecked={true} /> <label>white-coat</label>
                            </div>
                              : <div>
                                <input type='checkbox' name='whitecoatbody' value={'white-coat'}
                                  onClick={(e) => Bodyexttypewhite(e)} /> <label>white-coat</label>
                              </div>
                            }

                          </div>
                          <div style={{ marginRight: 10 }}>
                            {coatingbody.exttypewhite == 'white-print' ? <div>
                              <input type='checkbox' name='whitecoatbody' value={'white-print'}
                                onClick={(e) => Bodyexttypewhite(e)} defaultChecked={true} /> <label>white-print</label>
                            </div>
                              : <div>
                                <input type='checkbox' name='whitecoatbody' value={'white-print'}
                                  onClick={(e) => Bodyexttypewhite(e)} /> <label>white-print</label>
                              </div>
                            }
                          </div>

                          <div>
                            {coatingbody.exttypewhite == 'no-white' ? <div>
                              <input type='checkbox' name='whitecoatbody' value={'no-white'}
                                onClick={(e) => Bodyexttypewhite(e)} defaultChecked={true} /> <label>no-white</label>
                            </div>
                              : <div>
                                <input type='checkbox' name='whitecoatbody' value={'no-white'}
                                  onClick={(e) => Bodyexttypewhite(e)} /> <label>no-white</label>
                              </div>
                            }
                          </div>
                        </div>

                      </div>
                    </div> :
                      <div>
                        {coatingbody.extcoating == item ?
                          <div>
                            <input type='checkbox' name='extcoatingbody'
                              value={item}
                              onClick={(e) => handle_bodychecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='extcoatingbody' value={item}
                              onClick={(e) => handle_bodychecked(e)}
                              disabled
                            /> {item}
                          </div>}
                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='extotherbody' placeholder={item} value={coatingbody.extother}
                          onChange={(e) => setCoatingbody({ ...coatingbody, extother: e.target.value })} style={{ width: 200 }} />
                      </div> :
                        <div>
                          {coatingbody.extcoating == item ?
                            <div>
                              <input type='checkbox' name='extcoatingbody' value={item}
                                onClick={(e) => handle_bodychecked(e)}
                                defaultChecked={true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='extcoatingbody' value={item}
                                onClick={(e) => handle_bodychecked(e)}
                                disabled
                              /> {item}
                            </div>}

                        </div>}
                    </div>
                  ))}
                </div>

                <div>
                  <label>Note : </label>
                  <input type='text' name='textnodebody' style={{ width: 350 }}
                    onChange={(e) => setCoatingbody({ ...coatingbody, note: e.target.value })}
                    value={coatingbody.note}
                  />

                </div>

              </div>

            </div>

            <hr></hr>

            <div>
              <div className='row-page' style={{ padding: 10 }}>

                <div style={{ width: 100 }}>
                  <b>Top end</b>
                  <div>
                    <input type='number' name='numbertopendtop'
                      value={coatingtop.number} style={{ width: 70 }}
                      onChange={(e) => setCoatingtop({ ...coatingtop, number: e.target.value })}
                      disabled
                    />
                  </div>
                </div>
                <div style={{ width: '50%' }}>

                  <div className='row-between-form'>
                    <b>Substrate :</b>
                    {dataform.coating.topend.substrate.map((item, i) => (
                      <div>
                        {coatingtop.substrate == item ? <div>
                          <input type='checkbox' name='substratetop'
                            value={item}
                            onClick={(e) => handle_topchecked(e)}
                            defaultChecked={true}
                            disabled
                          /> {item}
                        </div> :
                          <div>
                            <input type='checkbox' name='substratetop'
                              value={item}
                              onClick={(e) => handle_topchecked(e)}
                              disabled
                            />
                            {item}
                          </div>}
                      </div>
                    ))}
                  </div>
                  <div className='row-between-form'>
                    <b>Shape :</b>
                    {dataform.coating.topend.shape.map((item, i) => (
                      <div>{coatingtop.shape == item ?
                        <div>
                          <input type='checkbox' name='Shapetop' value={item}
                            onClick={(e) => handle_topchecked(e)}
                            defaultChecked={coatingtop.shape == item ? true : false}
                            disabled
                          /> {item}
                        </div>
                        : <div>
                          <input type='checkbox' name='Shapetop' value={item}
                            onClick={(e) => handle_topchecked(e)}
                            disabled
                          /> {item}

                        </div>}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ width: "90%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                <div className='row-between-form'>
                  <b>Int {" : "}</b>
                  {dataform.coating.int.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div>
                      <input type='text' name='intwhitetop'
                        value={coatingtop.intwhite}
                        placeholder={item}
                        onChange={(e) => setCoatingtop({ ...coatingtop, intwhite: e.target.value })}
                        disabled
                      />
                    </div> :
                      <div>
                        {coatingtop.intcoating == item ?
                          <div>
                            <input type='checkbox' name='intcoatingtop' value={item}
                              onClick={(e) => handle_topchecked(e)}
                              defaultChecked={coatingtop.intcoating == item && true}
                              disabled
                            /> {item}
                          </div>
                          : <div>
                            <input type='checkbox' name='intcoatingtop' value={item}
                              onClick={(e) => handle_topchecked(e)}
                              disabled
                            /> {item}
                          </div>
                        }

                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='Othertop' value={coatingtop.intother}
                          placeholder={item}
                          onChange={(e) => setCoatingtop({ ...coatingtop, intother: e.target.value })}
                          disabled
                        />
                      </div> :
                        <div>

                          {coatingtop.intcoating == item ?
                            <div>
                              <input type='checkbox' name='intcoatingtop' value={item}
                                onClick={(e) => handle_topchecked(e)}
                                defaultChecked={coatingtop.intcoating == item && true}
                                disabled
                              /> {item}
                            </div>
                            : <div>
                              <input type='checkbox' name='intcoatingtop' value={item}
                                onClick={(e) => handle_topchecked(e)}
                                disabled
                              /> {item}
                            </div>
                          }
                        </div>}
                    </div>
                  ))}
                </div>

                <div className='row-between-form'>
                  <b>Ext {" : "}</b>
                  {dataform.coating.ext.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div >
                      <div className='row-page'>
                        <input type='text' name='extwhitetop'
                          value={coatingtop.extwhite}
                          placeholder={item}
                          onChange={(e) => setCoatingtop({ ...coatingtop, extwhite: e.target.value })}
                          style={{ width: 200 }}
                        />
                        <SearchWhitecode whitecodeValue={whitecodeValue} sequence={"2"} />
                      </div>

                      <div className='row-page'>
                        <div style={{ marginRight: 10 }}>

                          {coatingtop.exttypewhite == 'white-coat' ? <div>
                            <input type='checkbox' name='whitecoattop' value={'white-coat'}
                              onClick={(e) => Tobexttypewhite(e)} defaultChecked={true} /> <label>white-coat</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoattop' value={'white-coat'}
                                onClick={(e) => Tobexttypewhite(e)} /> <label>white-coat</label>
                            </div>
                          }

                        </div>

                        <div style={{ marginRight: 10 }}>
                          {coatingtop.exttypewhite == 'white-print' ? <div>
                            <input type='checkbox' name='whitecoattop' value={'white-print'}
                              onClick={(e) => Tobexttypewhite(e)} defaultChecked={true} /> <label>white-print</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoattop' value={'white-print'}
                                onClick={(e) => Tobexttypewhite(e)} /> <label>white-print</label>
                            </div>
                          }
                        </div>

                        <div>
                          {coatingtop.exttypewhite == 'on-white' ? <div>
                            <input type='checkbox' name='whitecoattop' value={'on-white'}
                              onClick={(e) => Tobexttypewhite(e)} defaultChecked={true} /> <label>on-white</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoattop' value={'on-white'}
                                onClick={(e) => Tobexttypewhite(e)} /> <label>on-white</label>
                            </div>
                          }
                        </div>

                      </div>

                    </div> :
                      <div>
                        {coatingtop.extcoating[0] == item && true ?
                          <div>
                            <input type='checkbox' name='extcoatingtop' value={item}
                              onClick={(e) => handle_topchecked(e)}
                              defaultChecked={coatingtop.extcoating[0] == item && true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='extcoatingtop' value={item}
                              onClick={(e) => handle_topchecked(e)}
                            /> {item}
                          </div>}

                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='extothertop' value={coatingtop.extother} placeholder={item}
                          onChange={(e) => setCoatingtop({ ...coatingtop, extother: e.target.value })} style={{ width: 200 }} />
                      </div> :
                        <div>

                          {coatingtop.extcoating[0] == item && true ?
                            <div>
                              <input type='checkbox' name='extcoatingtop' value={item}
                                onClick={(e) => handle_topchecked(e)}
                                defaultChecked={coatingtop.extcoating[0] == item && true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='extcoatingtop' value={item}
                                onClick={(e) => handle_topchecked(e)}
                                disabled
                              /> {item}
                            </div>}
                        </div>

                    }
                    </div>
                  ))}
                </div>
                <div className='row-between-form'>
                  <div >
                    <label><b>for sale order : </b></label>
                    <input type='text' name='forsaleorder'
                      onChange={(e) => setCoatingtop({ ...coatingtop, forsaleorder: e.target.value })} style={{ width: 300 }}
                      value={coatingtop.forsaleorder}
                    />
                  </div>
                  <div>
                    <label>Note : </label>
                    <input type='text' name='textnodebody' style={{ width: 350 }}
                      onChange={(e) => setCoatingtop({ ...coatingtop, note: e.target.value })}
                      value={coatingtop.note}
                    />

                  </div>
                </div>
              </div>

            </div>

            <hr></hr>

            <div>
              <div className='row-page' style={{ padding: 10 }}>

                <div style={{ width: 100 }}>
                  <b>Bottom end</b>
                  <div><input type='number'
                    value={coatingbottom.number} name='numberbottomend' style={{ width: 70 }}
                    onChange={(e) => setCoatingbottom({ ...coatingbottom, number: e.target.value })}
                    disabled /></div>
                </div>
                <div style={{ width: "50%" }}>
                  <div className='row-between-form'>
                    <b>Substrate :</b>
                    {dataform.coating.bottomend.substrate.map((item, i) => (
                      <div>
                        {coatingbottom.substrate == item ?
                          <div>
                            <input type='checkbox' name='substratebottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='substratebottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              disabled
                            /> {item}
                          </div>
                        }
                      </div>
                    ))}
                  </div>
                  <div className='row-between-form'>
                    <b>Shape :</b>
                    {dataform.coating.bottomend.shape.map((item, i) => (
                      <div>
                        {coatingbottom.shape == item ?
                          <div>
                            <input type='checkbox' name='Shapebottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='Shapebottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              disabled
                            /> {item}
                          </div>
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div style={{ width: "90%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                <div className='row-between-form'>
                  <b>Int {" : "}</b>
                  {dataform.coating.int.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div>
                      <input type='text' name='intwhitebottom'
                        value={coatingbottom.intwhite} placeholder={item}
                        onChange={(e) => setCoatingbottom({ ...coatingbottom, intwhite: e.target.value })}
                        disabled />
                    </div> :
                      <div>
                        {coatingbottom.intcoating == item ?
                          <div>
                            <input type='checkbox' name='intcoatingbottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='intcoatingbottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              disabled
                            /> {item}

                          </div>}
                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='intotherbottom'
                          value={coatingbottom.intother} placeholder={item}
                          onChange={(e) => setCoatingbottom({ ...coatingbottom, intother: e.target.value })}
                          disabled />
                      </div> :
                        <div>
                          {coatingbottom.intcoating == item ?
                            <div>
                              <input type='checkbox' name='intcoatingbottom' value={item}
                                onClick={(e) => handle_bottomchecked(e)}
                                defaultChecked={true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='intcoatingbottom' value={item}
                                onClick={(e) => handle_bottomchecked(e)}
                                disabled
                              /> {item}

                            </div>}

                        </div>}
                    </div>
                  ))}
                </div>
                <div className='row-between-form'>
                  <b>Ext {" : "}</b>
                  {dataform.coating.ext.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div >

                      <div className='row-page'>
                        <input type='text' name='extwhitebottom'
                          value={coatingbottom.extwhite} placeholder={item}
                          onChange={(e) => setCoatingbottom({ ...coatingbottom, extwhite: e.target.value })}
                          style={{ width: 200 }}
                        />
                        <SearchWhitecode whitecodeValue={whitecodeValue} sequence={"3"} />
                      </div>

                      <div className='row-page'>
                        <div style={{ marginRight: 10 }}>

                          {coatingbottom.exttypewhite == 'white-coat' ? <div>
                            <input type='checkbox' name='whitecoatbottom' value={'white-coat'}
                              onClick={(e) => bottomexttypewhite(e)} defaultChecked={true} /> <label>white-coat</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoatbottom' value={'white-coat'}
                                onClick={(e) => bottomexttypewhite(e)} /> <label>white-coat</label>
                            </div>
                          }

                        </div>
                        <div style={{ marginRight: 10 }}>
                          {coatingbottom.exttypewhite == 'white-print' ? <div>
                            <input type='checkbox' name='whitecoatbottom' value={'white-print'}
                              onClick={(e) => bottomexttypewhite(e)} defaultChecked={true} /> <label>white-print</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoatbottom' value={'white-print'}
                                onClick={(e) => bottomexttypewhite(e)} /> <label>white-print</label>
                            </div>
                          }
                        </div>
                        <div>
                          {coatingbottom.exttypewhite == 'no-white' ? <div>
                            <input type='checkbox' name='whitecoatbottom' value={'no-white'}
                              onClick={(e) => bottomexttypewhite(e)} defaultChecked={true} /> <label>no-white</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoatbottom' value={'no-white'}
                                onClick={(e) => bottomexttypewhite(e)} /> <label>no-white</label>
                            </div>
                          }
                        </div>

                      </div>

                    </div> :
                      <div>
                        {coatingbottom.extcoating == item ?
                          <div>
                            <input type='checkbox' name='extcoatingbottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='extcoatingbottom' value={item}
                              onClick={(e) => handle_bottomchecked(e)}
                              disabled
                            /> {item}
                          </div>}
                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='extotherbottom' value={coatingbottom.extother} placeholder={item}
                          onChange={(e) => setCoatingbottom({ ...coatingbottom, extother: e.target.value })} style={{ width: 200 }} />
                      </div> :
                        <div>
                          {coatingbottom.extcoating == item ?
                            <div>
                              <input type='checkbox' name='extcoatingbottom' value={item}
                                onClick={(e) => handle_bottomchecked(e)}
                                defaultChecked={true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='extcoatingbottom' value={item}
                                onClick={(e) => handle_bottomchecked(e)}
                                disabled
                              /> {item}
                            </div>}
                        </div>}
                    </div>
                  ))}
                </div>
                <div className='row-between-form'>
                  <div>
                    <label><b>for sale order : </b></label>
                    <input type='text' name='forsaleorder'
                      onChange={(e) => setCoatingbottom({ ...coatingbottom, forsaleorder: e.target.value })} style={{ width: 350 }}
                      value={coatingbottom.forsaleorder}
                    />
                  </div>
                  <div>
                    <label>Note : </label>
                    <input type='text' name='textnodeBottom' value={coatingbottom.note} style={{ width: 350 }} onChange={(e) => setCoatingbottom({ ...coatingbottom, note: e.target.value })} />

                  </div>
                </div>
              </div>

            </div>

            <hr></hr>

            <div>
              <div className='row-page' style={{ padding: 10 }}>

                <div style={{ width: 100 }}>
                  <b>Ring</b>
                </div>
                <div style={{ width: "40%" }}>
                  <div className='row-between-form'>
                    <b>Dia : <input type='text' name='dia'
                      value={coatingring.dia}
                      onChange={(e) => setCoatingring({ ...coatingring, dia: e.target.value })}
                      disabled
                    /></b>

                    {dataform.coating.ring.dai.map((item, i) => (
                      <div>
                        {item == coatingring.ring_eartype ?
                          <div>
                            <input type='checkbox' name='coatingDia' value={item}
                              onClick={(e) => handle_Ringchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='coatingDia' value={item}
                              onClick={(e) => handle_Ringchecked(e)}
                              disabled
                            /> {item}
                          </div>}
                      </div>
                    ))}
                  </div>

                </div>
              </div>
              <div style={{ marginLeft: 110 }} className='row-page'>
                <div style={{ marginRight: 20 }}>
                  <label>Code :</label>
                  <input type='text' name='ringcode'
                    onChange={(e) => setCoatingring({ ...coatingring, ring_earcode: e.target.value })}
                    value={coatingring.ring_earcode}
                    disabled
                  />
                </div>
              </div>

              <div style={{ width: "90%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                <div className='row-between-form'>
                  <b>Int {" : "}</b>
                  {dataform.coating.int.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div>
                      <input type='text' name='intwhitebottom'
                        value={coatingring.intwhite} placeholder={item}
                        onChange={(e) => setCoatingring({ ...coatingring, intwhite: e.target.value })}
                        disabled
                      />
                    </div> :
                      <div>
                        {coatingring.intcoating == item ?
                          <div>
                            <input type='checkbox' name='intcoatingring' value={item}
                              onClick={(e) => handle_Ringchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='intcoatingring' value={item}
                              onClick={(e) => handle_Ringchecked(e)}
                              disabled
                            /> {item}

                          </div>}
                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='intotherring' value={coatingring.intother} placeholder={item}
                          onChange={(e) => setCoatingring({ ...coatingring, intother: e.target.value })}
                          disabled />
                      </div> :
                        <div>
                          {coatingring.intcoating == item ?
                            <div>
                              <input type='checkbox' name='intcoatingring' value={item}
                                onClick={(e) => handle_Ringchecked(e)}
                                defaultChecked={true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='intcoatingring' value={item}
                                onClick={(e) => handle_Ringchecked(e)}
                                disabled
                              /> {item}

                            </div>}

                        </div>}
                    </div>
                  ))}
                </div>
                <div className='row-between-form'>
                  <b>Ext {" : "}</b>
                  {dataform.coating.ext.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div >

                      <div className='row-page'>
                        <input type='text' name='extwhitering' value={coatingring.extwhite} placeholder={item}
                          onChange={(e) => setCoatingring({ ...coatingring, extwhite: e.target.value })}
                          style={{ width: 200 }}
                        />
                        <SearchWhitecode whitecodeValue={whitecodeValue} sequence={"4"} />
                      </div>
                      <div className='row-page'>
                        <div style={{ marginRight: 10 }}>

                          {coatingring.exttypewhite == 'white-coat' ? <div>
                            <input type='checkbox' name='whitecoatring' value={'white-coat'}
                              onClick={(e) => ringexttypewhite(e)} defaultChecked={true} /> <label>white-coat</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoatring' value={'white-coat'}
                                onClick={(e) => ringexttypewhite(e)} /> <label>white-coat</label>
                            </div>
                          }

                        </div>
                        <div style={{ marginRight: 10 }}>
                          {coatingring.exttypewhite == 'white-print' ? <div>
                            <input type='checkbox' name='whitecoatring' value={'white-print'}
                              onClick={(e) => ringexttypewhite(e)} defaultChecked={true} /> <label>white-print</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoatring' value={'white-print'}
                                onClick={(e) => ringexttypewhite(e)} /> <label>white-print</label>
                            </div>
                          }
                        </div>

                        <div>
                          {coatingring.exttypewhite == 'on-white' ? <div>
                            <input type='checkbox' name='whitecoatring' value={'on-white'}
                              onClick={(e) => ringexttypewhite(e)} defaultChecked={true} /> <label>on-white</label>
                          </div>
                            : <div>
                              <input type='checkbox' name='whitecoatring' value={'on-white'}
                                onClick={(e) => ringexttypewhite(e)} /> <label>on-white</label>
                            </div>
                          }
                        </div>

                      </div>
                    </div> :
                      <div>
                        {coatingring.extcoating == item ?
                          <div>
                            <input type='checkbox' name='extcoatingring' value={item}
                              onClick={(e) => handle_Ringchecked(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='extcoatingring' value={item}
                              onClick={(e) => handle_Ringchecked(e)}
                            /> {item}
                          </div>}
                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='extcoatingotherring' value={coatingring.extother} placeholder={item}
                          onChange={(e) => setCoatingring({ ...coatingring, extother: e.target.value })} style={{ width: 200 }} />
                      </div> :
                        <div>
                          {coatingring.extcoating == item ?
                            <div>
                              <input type='checkbox' name='extcoatingring' value={item}
                                onClick={(e) => handle_Ringchecked(e)}
                                defaultChecked={true}
                                disabled
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='extcoatingring' value={item}
                                onClick={(e) => handle_Ringchecked(e)}
                                disabled
                              /> {item}
                            </div>}
                        </div>}
                    </div>
                  ))}

                </div>
                <div className='row-page'>
                  <div>
                    <label><b>for sale order : </b></label>
                    <input type='text' name='forsaleorder' style={{ width: 350 }}
                      onChange={(e) => setCoatingring({ ...coatingring, forsaleorder: e.target.value })}
                      value={coatingring.forsaleorder}

                    />
                  </div>
                </div>

              </div>
            </div>

            <hr></hr>

            <div>
              <div className='row-page' style={{ padding: 10 }}>

                <div style={{ width: 100 }}>
                  <b>Ear</b>
                </div>
              </div>
              <div style={{ marginLeft: 110, width: '40%' }} className='row-page'>

                <div style={{ marginRight: 20 }}>
                  <label>Code :</label>
                  <input type='text' name='earcode'
                    onChange={(e) => setCoatingear({ ...coatingear, ring_earcode: e.target.value })}
                    value={coatingear.ring_earcode}
                    disabled
                  />
                </div>
                <div className='row-between-form' style={{ width: '30%' }}>
                  {dataform.coating.ear.eartype.map((item, i) => (
                    <div>
                      {item == coatingear.ring_eartype ?
                        <div>
                          <input type='checkbox' name='eartype' value={item} onClick={(e) => handle_earCheck(e)}
                            defaultChecked={true}
                            disabled
                          /> {item}
                        </div> : <div>
                          <input type='checkbox' name='eartype' value={item} onClick={(e) => handle_earCheck(e)}
                            disabled
                          /> {item}
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ width: "90%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                <div className='row-between-form'>
                  <b>Int {" : "}</b>
                  {dataform.coating.int.data.map((item, i) => (
                    <div>{item == 'white-coat' ? <div>
                      <input type='text' name='intwhiteear' value={coatingring.intwhite} placeholder={item}
                        onChange={(e) => setCoatingear({ ...coatingring, intwhite: e.target.value })}

                      />
                    </div> :
                      <div>
                        {coatingear.intcoating == item ?
                          <div>
                            <input type='checkbox' name='intcoatingear' value={item}
                              onClick={(e) => handle_earCheck(e)}
                              defaultChecked={true}
                              disabled
                            /> {item}
                          </div> :
                          <div>
                            <input type='checkbox' name='intcoatingear' value={item}
                              onClick={(e) => handle_earCheck(e)}
                              disabled
                            /> {item}

                          </div>}
                      </div>
                        && item == 'Other' ? <div>
                        <input type='text' name='intotherear' value={coatingring.intother} placeholder={item}
                          onChange={(e) => setCoatingear({ ...coatingring, intother: e.target.value })} />
                      </div> :
                        <div>
                          {coatingear.intcoating == item ?
                            <div>
                              <input type='checkbox' name='intcoatingear' value={item}
                                onClick={(e) => handle_earCheck(e)}
                                disabled
                                defaultChecked={true}
                              /> {item}
                            </div> :
                            <div>
                              <input type='checkbox' name='intcoatingear' value={item}
                                onClick={(e) => handle_earCheck(e)}
                                disabled
                              /> {item}

                            </div>}

                        </div>}
                    </div>
                  ))}

                </div>
                <span>
                  <label style={{ marginRight: 5 }}>PROOF : </label>
                  {coatingear.statusProof == 1 ?

                    <input type='checkbox' name='earProof'
                      value={1} onClick={(e) => handle_earCheck(e)}
                      defaultChecked={true} /> :
                    <input type='checkbox' name='earProof'
                      value={1} onClick={(e) => handle_earCheck(e)}
                    />
                  }

                </span>

              </div>
            </div>

          </div>


        </div>
      </div>
      }
    </div>

  )
}


const Approvebyprepass = ({ id, journalcode, typeproduct }) => {

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  return (
    <div>

      <Button size='sm' variant="primary" onClick={() => handleShow(true)}>
        จัดการเอกสาร
      </Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {typeproduct == 'A1' ? <label>TECHNICAL DATA SHEET : Food can Product</label> :
              <label>TECHNICAL DATA SHEET : Dry foods ,Arosol can and General can Product</label>}<b></b> ( {journalcode} )</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditJournal id={id} />
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Approvebyprepass