import React, { useEffect, useState } from 'react'

import Modal from 'react-bootstrap/Modal';
import Actionrecalljournal from './actionrecalljournal';
import Actionapprovestatus from './actionapprovestatus';
import Actionrename from './actionrename';
import Actionexampleofwork from './actionexampleofwork';
import Actionrevision from './actionrevision';
import ActionConfirmproduct from './actionConfirmproduct';
import { Button } from 'react-bootstrap';
import FetchApi from '../../../customhooks/functionFetchApi';

const Actionall = ({ id, journalcode, stateflow, stateSuccesfull,revision }) => {
    const [journallist, setJournallist] = useState([])
    const FetchApis=new FetchApi();

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const[checkdata,setCheckdata]=useState(false)

    function handleShow() {
        setShow(true);
    }
    
    useEffect(()=>{
        FetchApis.FethcGet(`/coating/coatingByJournalId/${id}`).then((res)=>{
            if(res.data.status==200){
                if(res.data.data.length==0){
                    setCheckdata(true)
                } else{
                    setCheckdata(false)
                }   
            }
        })  
    },[])

    return (
        <div className='row-page'>
            <div>
                <Button size='sm' onClick={() => handleShow(true)}>จัดการ</Button>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                // fullscreen={fullscreen}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        สถานะเอกสาร ( {journalcode} )
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row-page'>
                        {stateflow > stateSuccesfull ? <div><Actionrevision id={id} actionState={false} codetds={journalcode} revision={revision}/> </div> : <div><Actionrevision id={id} actionState={true} /> </div>}
                        <div style={{ marginLeft: 10 }} ><Actionapprovestatus id={id} /></div>
                        {stateflow > stateSuccesfull ? <div style={{ marginLeft: 10 }}><Actionrename id={id} actionState={false} /></div> : <div style={{ marginLeft: 10 }}><Actionrename id={id} actionState={true} /></div>}
                        <div style={{ marginLeft: 10 }}>
                            {checkdata==true?<Actionexampleofwork id={id} actionState={true}/>:<Actionexampleofwork id={id} actionState={false}/>}
                        </div>
                        <div style={{ marginLeft: 10 }} > {checkdata==true?<ActionConfirmproduct id={id} actionState={true}/>:<ActionConfirmproduct id={id} actionState={false}/>} </div>
                        <div style={{ marginLeft: 10 }}>
                            {checkdata==true?<Actionrecalljournal id={id} actionState={true}/>:<Actionrecalljournal id={id} actionState={false}/>}
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Actionall