import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../../customhooks/functionFetchApi';
import PlanApprove from './planApprove';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SearchSheetsize from './searchSheetsize';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import { CreateNewItemTo } from './service/servicecreateItem';
import CreateNewitemToerp from './createNewitemToerp';
import { useSelector, useDispatch } from 'react-redux';
import PopupItemList from './popupItemList';
import PopupitemWcList from './popupitemWcList';
// 

const datasub = {
  spectype: "",
  sheetsize: "",
  flatscroll: "",
  straightscroll: "",
  tin: "",
  temper: "",
  bright: "",
  row: "",
  filmcode: "",
  itemno: "",
  refitemwc: "",
  journalId: "",
  tdscode: "",
}


function FormAddspec({ jourId, handleShow }) {

  const itemnewBody = useSelector((state) => state.newitems.typebody)
  const itemnewTop = useSelector((state) => state.newitems.typetop)
  const itemnewBottom = useSelector((state) => state.newitems.typebottom)
  const itemnewTab = useSelector((state) => state.newitems.typetab)

  const FetchApis = new FetchApi()
  const [key, setKey] = useState('jouranl');

  const [datafetch, setDatafetch] = useState([]);

  //รับค่าตัวแปลมมาทำการสร้าง new item
  const [datanewItem, setDataNewItem] = useState([]);


  // add substrate new 
  const [substratebody, setSubstrateBody] = useState([]);
  const [substratebodyCheck, setSubstratebodyCheck] = useState(false); // ตรวจสอบว่ามีข้อมูล substratebody หรือหยัง 

  const [substrateTop, setSubstrateTop] = useState([]);
  const [substrateTopCheck, setSubstrateTopCheck] = useState(false);// ตรวจสอบว่ามีข้อมูล substrateTop หรือหยัง

  const [substrateBottom, setSubstrateBottom] = useState([]);
  const [substrateBottomCheck, setSubstrateBottomCheck] = useState(false); // ตรวจสอบว่ามีข้อมูล substrateBottom หรือหยัง

  const [substrateTab, setSubstrateTab] = useState([]);
  const [substrateTabCheck, setSubstrateTabCheck] = useState(false); // ตรวจสอบว่ามีข้อมูล substrateTab หรือหยัง

  const [adddatatype, setAdddatatype] = useState({ ...datasub })

  const [componentCode, setComponentCode] = useState({
    ear: "",
    earwelding: "",
    ring: "",
    innerlid: "",
    outerlid: "",
    toplid: "",
    bottomlid: "",
    plasticlid: "",
    aluminumlid: "",
    journalId: "",
    tdscode: ""
  })

  const [checkdataspec, setCheckdataspec] = useState(false)

  useEffect(() => {
    var codeid = "";
    FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((res) => {
      if (res.data.status == 200) {
        setDatafetch(res.data.data[0])

        codeid = res.data.data[0].tdscode;

        getSubstrateAll(jourId);

        getcomponentcode(jourId, codeid);

        //set data 
        setAdddatatype({
          ...adddatatype,
          tdscode: codeid,
          journalId: jourId
        })

      }
    })

  }, [])

  async function getSubstrateAll(Id) {

    await FetchApis.FethcGet(`/specplanning/specplanningByJournalId/${Id}`).then((res) => {
      if (res.data.status == 200) {
        // console.log(res.data.data.length)
        if (res.data.data.length > 0) {
          setCheckdataspec(true)
        }

        for (let index = 0; index < res.data.data.length; index++) {

          if (res.data.data[index].spectype == 'body') {
            setSubstrateBody(res.data.data[index]);

            setSubstratebodyCheck(true)
          }
          else if (res.data.data[index].spectype == 'top') {
            setSubstrateTop(res.data.data[index])
            setSubstrateTopCheck(true);
          }
          else if (res.data.data[index].spectype == 'bottom') {
            setSubstrateBottom(res.data.data[index])
            setSubstrateBottomCheck(true)
          }
          else if (res.data.data[index].spectype == 'tab') {
            setSubstrateTab(res.data.data[index])
            setSubstrateTabCheck(true)
          }
        }
      }
    })
  }

  async function getcomponentcode(Id, codeid) {

    await FetchApis.FethcGet(`/componentcode/componentByjournalId/${Id}`).then((res) => {
      if (res.status == 200) {
        if (res.data.data.length > 0) {
          setComponentCode(res.data.data[0]);

        }
        else {

          console.log(res.data.data[0])
          setComponentCode({
            ...componentCode,
            tdscode: codeid,
            journalId: jourId
          })
        }
      }
    })
  }

  const submitform = (e) => {

    e.preventDefault();


    if (substratebody == "" && substrateTop == "" && substrateBottom == "" && substrateTab == "") {
      AlertError()
    } else {


      Swal.fire({
        title: "บันทึกเอกสาร",
        text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: 'ยกเลิก'
      }).then((result) => {
        if (result.isConfirmed) {

          toast.success('บันทึกเรียบร้อยแล้ว', {
            position: "top-right",
            autoClose: 1300,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          CreateNewItemTo(datanewItem);

          if (substratebody != "" && substratebodyCheck == false) {
            if (substratebody.sheetsize != "" || substratebody.itemno != "") {
              FetchApis.FethcPost('/specplanning/createspecplanning', substratebody)
            }

          }

          if (substrateTop != "" && substrateTopCheck == false) {
            if (substrateTop.sheetsize != "" || substrateTop.itemno != "") {
              FetchApis.FethcPost('/specplanning/createspecplanning', substrateTop)
            }

          }

          if (substrateBottom != "" && substrateBottomCheck == false) {
            if (substrateBottom.sheetsize != "" || substrateBottom.itemno != "") {
              FetchApis.FethcPost('/specplanning/createspecplanning', substrateBottom)
            }

          }

          if (substrateTab != "" && substrateTabCheck == false) {
            if (substrateTab.sheetsize != "" || substrateTab.itemno != "") {
              FetchApis.FethcPost('/specplanning/createspecplanning', substrateTab)
            }
          }


          // update component code 
          FetchApis.FethcUpdate(`/componentcode/updatecomponentcode/${componentCode.comId}`, componentCode).then((res) => {

            if (res.status != 200) {
              alert(res.message)
            }
          }).catch((err) => { console.log(err) })

          // update header film code 

          FetchApis.FethcUpdate(`/journal/journalupdate/${datafetch.journalId}`,datafetch).then((res) => {

            if (res.status != 200) {
              alert(res.message)
            }
          }).catch((err) => { console.log(err) })


          setTimeout(() => {
            handleShow(false);
            window.location.reload();
          }, 1500);

        }

      });
    }

  }


  const AlertError = () => {
    Swal.fire({
      title: "แจ้งเตือน",
      text: "ไม่สามารถบันทึกได้เนื่องจากไม่มีข้อมูล",
      icon: "question"
    });
  }

  const onchangeTraget = (e) => {
    var typesubstrate = e.target.value;
    if (typesubstrate == "body") {
      setAdddatatype({ ...adddatatype, spectype: typesubstrate, itemno: itemnewBody })
    }
    else if (typesubstrate == "top") {
      setAdddatatype({ ...adddatatype, spectype: typesubstrate, itemno: itemnewTop })
    } else if (typesubstrate == "bottom") {
      setAdddatatype({ ...adddatatype, spectype: typesubstrate, itemno: itemnewBottom })
    }
    else if (typesubstrate == 'tab') {
      setAdddatatype({ ...adddatatype, spectype: typesubstrate, itemno: itemnewTab })
    }

  }

  const addItemsubStrate = () => {

    if (adddatatype.spectype == 'body') {

      if (substratebodyCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถบันทึกได้เนื่องจากมีข้อมูล substrate body อยู่แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateBody({ ...adddatatype })
      }

    }
    else if (adddatatype.spectype == 'top') {
      if (substrateTopCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถบันทึกได้เนื่องจากมีข้อมูล substrate top อยู่แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateTop({ ...adddatatype })
      }

    }
    else if (adddatatype.spectype == 'bottom') {
      if (substrateBottomCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถบันทึกได้เนื่องจากมีข้อมูล substrate bottom อยู่แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateBottom({ ...adddatatype })
      }

    }
    else if (adddatatype.spectype == 'tab') {
      if (substrateTabCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถบันทึกได้เนื่องจากมีข้อมูล substrate tab อยู่แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateTab({ ...adddatatype })
      }

    }
    else {
      //แจ้งเตือน
      Swal.fire({
        title: "แจ้งเตือน",
        text: "คุณยังไม่ได้เลือก ประเภท",
        icon: "question"
      });
    }

  }

  const resetItemsubStrate = () => {

    if (adddatatype.spectype == 'body') {
      if (substratebodyCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถ ยกเลิกได้เนื่องจากมีข้อมูล substrate Body แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateBody({ ...datasub })
      }

    }
    else if (adddatatype.spectype == 'top') {
      if (substrateTopCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถ ยกเลิกได้เนื่องจากมีข้อมูล substrate Top แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateTop({ ...datasub })
      }

    }
    else if (adddatatype.spectype == 'bottom') {
      if (substrateBottomCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถ ยกเลิกได้เนื่องจากมีข้อมูล substrate Bottom แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateBottom({ ...datasub })
      }

    }
    else if (adddatatype.spectype == 'tab') {
      if (substrateTabCheck == true) {
        //แจ้งเตือน
        Swal.fire({
          title: "แจ้งเตือน",
          text: "ไม่สามารถ ยกเลิกได้เนื่องจากมีข้อมูล substrate tab แล้ว",
          icon: "question"
        });
      } else {
        setSubstrateTab({ ...datasub })
      }
    }
  }

  function handle_Adddatatype(values) {
    setAdddatatype({ ...adddatatype, sheetsize: values })

  }

  // fucntion รับค่ามาบันทึก New item
  function CreateNewItem(datavalues) {
    setDataNewItem(datavalues)

  }

  function selectItemId(itemnames) {

    setAdddatatype({
      ...adddatatype,
      itemno: itemnames
    })

  }


  function selectItemWC(itemWc) {

    setAdddatatype({
      ...adddatatype,
      refitemwc: itemWc
    })
  }


  return (
    <div className='form-body-page'>
      <form onSubmit={(e) => submitform(e)} >
        <div className='end-flex-row'>
          <Button type='submit' size='sm' variant="primary" style={{ marginRight: 10 }}>บันทึกข้อมูล </Button>
          <Button type='reset' size='sm' variant="warning" style={{ marginRight: 10 }} >ยกเลิก</Button>
          {checkdataspec == true && <PlanApprove idjournal={jourId} stateflow={datafetch.stateflow} />}
        </div>

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"

        >
          <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={jourId} />
          </Tab>
          <Tab eventKey="NewItem" title="สร้าง New Item to ERP" style={{ backgroundColor: 'aliceblue' }}>
            {datafetch.length !== 0 && <CreateNewitemToerp datajournal={datafetch} CreateNewItem={CreateNewItem} />}
          </Tab>
          <Tab eventKey="body" title="เพิ่มข้อมูล spec" style={{ backgroundColor: 'aliceblue' }}>

            <div className='row-between-page' style={{ width: 500, backgroundColor: '#EEEDEB', padding: 5, borderRadius: 5 }}>

              <div>
                <input type='radio' name='typesub' value={'body'} onChange={(e) => onchangeTraget(e)} /> Body
              </div>
              <div>
                <input type='radio' name='typesub' value={'top'} onChange={(e) => onchangeTraget(e)} /> Top end
              </div>
              <div>
                <input type='radio' name='typesub' value={'bottom'} onChange={(e) => onchangeTraget(e)} /> Bottom end
              </div>
              <div>
                <input type='radio' name='typesub' value={'tab'} onChange={(e) => onchangeTraget(e)} /> Tab
              </div>
              <div>
                <Button variant="primary" size='sm' onClick={addItemsubStrate} style={{ marginRight: 20 }}>เพิ่มข้อมูล</Button>

                <Button variant="warning" size='sm' onClick={resetItemsubStrate} >ยกเลิก</Button>
              </div>
            </div>
            <div>
            </div>

            <div className='row-between-page' style={{ width: '100%' }}>
              <div style={{ width: '100%', padding: 5 }} className='row-between-page border_green'>

                <div style={{ width: '50%' }}>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Sheet size (T x W x L)</div>
                    <div className='row-page'>
                      <SearchSheetsize handle_Adddatatype={handle_Adddatatype} />
                      <input type='text' name='Sheetsize' value={adddatatype.sheetsize}
                        onChange={(e) => setAdddatatype({ ...adddatatype, sheetsize: e.target.value })}
                        style={{ width: 200, marginLeft: 5 }} />

                    </div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Straight / Scoll </div>
                    <div style={{ width: '30%' }} >
                      <div>
                        <input type='radio' name='StraightScoll' value={'Straight'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label>Straight</label>
                      </div>
                      {" "}
                      <div>
                        <input type='radio' name='StraightScoll' value={'Scoll'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label> Scoll</label>
                      </div>
                      {" "}
                      <div>
                        <input type='radio' name='StraightScoll' value={'Coil Feed'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label> Coil Feed</label>
                      </div>
                      {" "}
                      <div>
                        <input type='radio' name='StraightScoll' value={'Coil Tab'}
                          onChange={(e) => setAdddatatype({ ...adddatatype, straightscroll: e.target.value })} /> <label> Coil Tab</label>
                      </div>
                      {" "}

                    </div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Tin coating /Alloy </div>
                    <div><input type='text' name='Tincoating'
                      onChange={(e) => setAdddatatype({ ...adddatatype, tin: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Temper / Hardness</div>
                    <div><input type='text' name='TemperHardness' onChange={(e) => setAdddatatype({ ...adddatatype, temper: e.target.value })} style={{ width: 150 }} /></div>
                  </div>

                </div>

                <div style={{ width: '50%', marginLeft: 5 }}>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Bright / Stone / Matte</div>
                    <div><input type='text' name='BrightStone' onChange={(e) => setAdddatatype({ ...adddatatype, bright: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Row x Layer</div>
                    <div><input type='text' name='RowLayer' onChange={(e) => setAdddatatype({ ...adddatatype, row: e.target.value })} style={{ width: 150 }} /></div>
                  </div>
                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Film code</div>
                    <div><input type='text' name='FilmCode' onChange={(e) => setAdddatatype({ ...adddatatype, filmcode: e.target.value })} style={{ width: 150 }} /></div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Item No.</div>
                    <div className='row-between-page'>
                      <PopupItemList selectItemId={selectItemId} />
                      <input type='text' name='ItemNo'
                        value={adddatatype.itemno}
                        onChange={(e) => setAdddatatype({ ...adddatatype, itemno: e.target.value })} style={{ width: 250, marginLeft: 5 }} /></div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>Ref. Item WC</div>

                    <div className='row-between-page'>
                      <PopupitemWcList selectItemWC={selectItemWC} />
                      <input type='text' name='refitemwc'
                        value={adddatatype.refitemwc}
                        onChange={(e) => setAdddatatype({ ...adddatatype, refitemwc: e.target.value })}
                        style={{ width: 250, marginLeft: 5 }}
                      />
                    </div>
                  </div>

                  <div style={{ width: '100%' }} className='row-between-page'>
                    <div>
                      Item Name
                    </div>
                    <div>{datafetch.length > 0 && <div><b>{datafetch.itemname}</b></div>}</div>
                  </div>

                </div>
              </div>

              <div style={{ marginLeft: '10%', backgroundColor: '#F0F0F0', padding: 10, borderRadius: 5 }}>
                <center><div style={{ backgroundColor: '#989898' }}>เพิ่ม Component Code</div></center>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>หูอ๊อก/ถ้วยอ๊อก</div>
                  <div>
                    <input type='text' name='Earwelding'
                      defaultValue={componentCode.earwelding}
                      onChange={(e) => setComponentCode({ ...componentCode, earwelding: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>หู</div>
                  <div>
                    <input type='text' name='Ear'
                      defaultValue={componentCode.ear}
                      onChange={(e) => setComponentCode({ ...componentCode, ear: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ห่วง</div>
                  <div>
                    <input type='text' name='Ring'
                      defaultValue={componentCode.ring}
                      onChange={(e) => setComponentCode({ ...componentCode, ring: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาใน</div>
                  <div><input type='text' name='Innerlid'
                    defaultValue={componentCode.innerlid}
                    onChange={(e) => setComponentCode({ ...componentCode, innerlid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาบน</div>
                  <div><input type='text' name='Toplid'
                    defaultValue={componentCode.toplid}
                    onChange={(e) => setComponentCode({ ...componentCode, toplid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div>

                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาพลาสติก</div>
                  <div><input type='text' name='Plasticlid'
                    defaultValue={componentCode.plasticlid}
                    onChange={(e) => setComponentCode({ ...componentCode, plasticlid: e.target.value })}
                    style={{ width: 300 }} /></div>
                </div>
                <div className='row-page' style={{ marginTop: 10 }}>
                  <div style={{ width: 100 }}>ฝาอลูมิเนียม</div>
                  <div>
                    <input type='text' name='Aluminumlid'
                      defaultValue={componentCode.aluminumlid}
                      onChange={(e) => setComponentCode({ ...componentCode, aluminumlid: e.target.value })}
                      style={{ width: 300 }} />
                  </div>
                </div>
              </div>

            </div>
            <hr></hr>
            <div className='row-between-page'>
              <hr></hr>
              <table style={{ width: '100%' }}>
                <tr>
                  <th style={{ width: '10%' }}><center>Substrate</center></th>
                  <th style={{ width: '10%' }}><center>Body</center></th>
                  <th style={{ width: '10%' }}><center>Top end</center></th>
                  <th style={{ width: '10%' }}><center>Bottom end</center></th>
                  <th style={{ width: '10%' }}><center>Tab</center></th>
                </tr>
                <tr>
                  <td><b>Sheet size(T x W x L)</b></td>
                  <td>{substratebody.sheetsize}</td>
                  <td>{substrateTop.sheetsize}</td>
                  <td>{substrateBottom.sheetsize}</td>
                  <td>{substrateTab.sheetsize}</td>
                </tr>
                <tr>
                  <td><b>Straight / Scoll</b></td>
                  <td>{substratebody.straightscroll}</td>
                  <td>{substrateTop.straightscroll}</td>
                  <td>{substrateBottom.straightscroll}</td>
                  <td>{substrateTab.straightscroll}</td>
                </tr>
                <tr>
                  <td><b>Tin coating / Alloy</b></td>
                  <td>{substratebody.tin}</td>
                  <td>{substrateTop.tin}</td>
                  <td>{substrateBottom.tin}</td>
                  <td>{substrateTab.tin}</td>
                </tr>
                <tr>
                  <td><b>Temper / Hardness</b></td>
                  <td>{substratebody.temper}</td>
                  <td>{substrateTop.temper}</td>
                  <td>{substrateBottom.temper}</td>
                  <td>{substrateTab.temper}</td>
                </tr>
                <tr>
                  <td><b>Bright / Stone / Matte</b></td>
                  <td>{substratebody.bright}</td>
                  <td>{substrateTop.bright}</td>
                  <td>{substrateBottom.bright}</td>
                  <td>{substrateTab.bright}</td>
                </tr>
                <tr>
                  <td><b>Row x Layer</b></td>
                  <td>{substratebody.row}</td>
                  <td>{substrateTop.row}</td>
                  <td>{substrateBottom.row}</td>
                  <td>{substrateTab.row}</td>
                </tr>
                <tr>
                  <td><b>Film code</b></td>
                  <td>{substratebody.filmcode}</td>
                  <td>{substrateTop.filmcode}</td>
                  <td>{substrateBottom.filmcode}</td>
                  <td>{substrateTab.filmcode}</td>
                </tr>
                <tr>
                  <td><b>Item No.</b></td>
                  <td>{substratebody.itemno}</td>
                  <td>{substrateTop.itemno}</td>
                  <td>{substrateBottom.itemno}</td>
                  <td>{substrateTab.itemno}</td>
                </tr>
                <tr>
                  <td><b>Ref.Item WC.</b></td>
                  <td>{substratebody.refitemwc}</td>
                  <td>{substrateTop.refitemwc}</td>
                  <td>{substrateBottom.refitemwc}</td>
                  <td>{substrateTab.refitemwc}</td>
                </tr>
              </table>
              <div className='border_green' style={{ width: '30%', height: 'auto', padding: 10 }}>
                <div style={{ backgroundColor: '#989898' }}>
                  <center>Component Code </center>
                </div>
                <div className='row-between-page '>
                  <div><b>หูออ๊ก :</b></div>
                  <div>{componentCode.earwelding}</div>

                </div>
                <div className='row-between-page '>
                  <div><b>หู :</b></div>
                  <div>{componentCode.ear}</div>

                </div>
                <div className='row-between-page '>
                  <div><b>ห่วง :</b></div>
                  <div>{componentCode.ring}</div>

                </div>
                <div className='row-between-page '>
                  <div><b>ฝาใน :</b></div>
                  <div>{componentCode.innerlid}</div>
                </div>
                <div className='row-between-page '>
                  <div><b>ฝาบน :</b></div>
                  <div>{componentCode.toplid}</div>
                </div>
                <div className='row-between-page '>
                  <div><b>ฝาพลาสติก :</b></div>
                  <div>{componentCode.plasticlid}</div>
                </div>
                <div className='row-between-page '>
                  <div><b>ฝาอลูมิเนียม :</b></div>
                  <div>{componentCode.aluminumlid}</div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="EditLastfilmCode" title="แก้ไข Last film Code" style={{ backgroundColor: 'aliceblue' }}>

            <div>
              <div>
                <div><center><b style={{ fontSize: 15 }}>TECHNICAL DATA SHEET : {datafetch.typeproduct == 'A1' ? "Food can Product" : "Dry foods ,Arosol can and General can Product"}</b></center></div>
                <div>
                  <table style={{ width: '100%' }} >
                    <tr>
                      <th><center>Create product</center></th>
                      <th><center>Due Date</center></th>
                      <th><center>MK NO</center></th>
                      <th><center>Date Cretae</center></th>
                    </tr>
                    <tr>
                      <th style={{ paddingLeft: 5 }}>{datafetch.namejournaltype}</th>
                      <th style={{ paddingLeft: 5 }}>{datafetch.duedate}</th>
                      <th style={{ paddingLeft: 5 }}>{datafetch.tdscode}</th>
                      <th style={{ paddingLeft: 5 }}>{datafetch.createdate}</th>
                    </tr>
                  </table>
                </div>

                <div style={{ width: '100%' }} className='row-between-form'>
                  <div style={{ width: '50%' }} className='border_green'>
                    <table style={{ width: '100%' }} >
                      <tr>
                        <td style={{ width: '30%' }}>Product name : </td>
                        <td>{datafetch.productname}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '30%' }}>Can Size /Dia : </td>
                        <td> {datafetch.cansize}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '30%' }}>Shape : </td>
                        <td> {datafetch.shape}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '30%' }}>Item name : </td>
                        <td>{datafetch.itemname}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '30%' }}>Item NO : </td>
                        <td>{datafetch.itemcode}</td>
                      </tr>

                    </table>
                  </div>
                  <div style={{ width: '50%', marginLeft: 3 }} className='border_green'>
                    <table style={{ width: '100%' }} >
                      <tr>
                        <td style={{ width: '30%' }}>Customer name : </td>
                        <td>{datafetch.customername}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '30%' }}>Contact : </td>
                        <td>{datafetch.contact} </td>
                      </tr>
                      <tr>
                        <td style={{ width: '30%' }}>Tel : </td>
                        <td>{datafetch.tel} </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <th>Substrate</th>
                      <th>Product group</th>
                      <th>Information from customer</th>
                      <th>Printing system</th>
                    </tr>
                    <tr>
                      <td><input type='checkbox' checked={true} disabled /> {datafetch.substratetype}</td>
                      <td><input type='checkbox' checked={true} disabled /> {datafetch.productgrouptype}</td>
                      <td><input type='checkbox' checked={true} disabled /> {datafetch.informationtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล ' : datafetch.informationtype}</td>
                      <td>
                        <tr style={{ width: '100%' }}>
                          <td style={{ width: '20%' }}>
                            <label style={{ width: 200 }}><b>Printing System</b></label>
                          </td>
                          <td>
                            <input type='checkbox' checked={true} disabled /> {datafetch.printingtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล  ( printing system ) ' : datafetch.printingtype}
                            {" "}    {datafetch.printingtypeother !== "" && (datafetch.printingtypeother)}
                          </td>
                        </tr>
                        <tr>
                          <td><label><b>Printing Machine</b></label></td>
                          <td>
                            {datafetch.printingmachine}
                          </td>
                        </tr>
                        <tr >
                          <td style={{ width: '30%' }}><b>Last film Code:</b></td>
                          <td style={{ width: '100%' }}>
                            {/* {datafetch[0].filmcode} */}
                            <input type='text' name='lestfilmcode' value={datafetch.filmcode} style={{ width: 350 }}
                              onChange={(e) => setDatafetch({ ...datafetch, filmcode: e.target.value })} />
                          </td>
                        </tr>
                        <tr >
                          <td style={{ width: '30%' }}><b>Ref.item FG:</b></td>
                          <td style={{ width: '100%' }}>{datafetch.refFgcode}</td>
                        </tr>
                        <tr>
                          <td>
                            {datafetch.filmtype == 'Scrap' ? <div><input type='checkbox' checked={true} disabled /> Scrap</div> : <div><input type='checkbox' checked={false} disabled /> Scrap</div>}
                          </td>
                          <td>
                            {datafetch.filmtype == 'Maintain' ? <div><input type='checkbox' checked={true} disabled /> Maintain</div> : <div><input type='checkbox' checked={false} disabled /> Maintain</div>}
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </div>

                <div className='row-page'>
                  <div>
                    <b><label>STATUS PROOF JOB</label></b>
                    {datafetch.statusprove_Tds == 1 ? <div>
                      <input type='checkbox' name='noteapproveTds' checked={true} disabled /> <label> PROOF JOB</label>
                    </div> : <div>
                      <input type='checkbox' name='noteapproveTds' checked={false} disabled /> <label> PROOF JOB</label>
                    </div>}
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <div>
                      <b><label>NOTE</label></b>
                    </div>
                    <div>
                      <label>{datafetch.noteapproveTds}</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </Tab>
        </Tabs>
      </form>
    </div>
  )
}


const PlanningAddspec = ({ journalId }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    // setFullscreen(breakpoint);
    setShow(value);
  }

  return (
    <div >
      <Button style={{ fontSize: 12 }} onClick={() => handleShow(true)} >เพิ่ม spec</Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ข้อมูล Spec</Modal.Title>
        </Modal.Header>
        <Modal.Body><FormAddspec jourId={journalId} handleShow={handleShow} /></Modal.Body>
      </Modal>
    </div>
  )
}

export default PlanningAddspec